import React, { useState, useCallback, useEffect } from "react";
import { Button, Grid, makeStyles, MenuItem } from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MainTemplate from "templates/MainTemplate";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import { NetworkFailure } from "features/core/NetworkFailure";
import {
  fetchCryptoNetworks,
  fetchCryptoWalletsManagement,
  postCryptoTransfer,
} from "@portit/core/api/Crypto";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    marginTop: 32,
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  form: {
    maxWidth: "100%",
  },
  flex1: {
    flex: 1,
  },
  mt56: {
    marginTop: 56,
  },
  mt16: {
    marginTop: 16,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  sectionTitle: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    marginBottom: "5px",
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  checkboxWrapper: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
    textAlign: "center",
    alignItems: "center",
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 56,
    gap: "1rem",
  },
  "& .actions": {
    minHeight: 50,
    margin: "0 10px",

    "&-reject": {
      backgroundColor: "#FF6F0F",
    },
  },
}));

interface FormValues {
  amount: number;
  walletAddress: string;
  destinationAddress: string;
  smartContractAddress: string;
  network: string;
}

type SelectOptions = {
  label: string;
  value: string;
};

const CryptoPaymentTransfer: React.FC = () => {
  const { t } = useTranslation("crypto");
  const classes = useStyles();
  const dispatch = useDispatch();

  const networks = [
    "ETHEREUM_ROPSTEN",
    "ETHEREUM_GOERLI",
    "BINANCE_TEST_NET",
    "AVALANCHE_FUJI",
    "POLYGON_MUMBAI",
    "ETHEREUM_MAIN_NET",
    "BINANCE_MAIN_NET",
    "AVALANCHE_MAIN_NET",
    "POLYGON_MAIN_NET",
  ];

  const [addresses, setAddresses] = useState<SelectOptions[]>([]);
  const [isFromWalletOutside, setIsFromWalletOutside] = useState(false);
  const [network, setNetwork] = useState<string>();
  const [profiles, setProfiles] = useState<
    { address: string; currency: string }[]
  >([]);

  const defaultValues = {
    amount: "",
    walletAddress: "",
    destinationAddress: "",
    smartContractAddress: "",
    network: "",
  };

  const getCryptoWalletsManagement = useCallback(async (): Promise<void> => {
    try {
      const res = await fetchCryptoWalletsManagement({
        size: 99999,
        page: 0,
      });
      const addresses = res?.wallets?.map((wallet) => {
        return {
          label: `${
            wallet?.customer?.user?.username ||
            wallet?.customer?.company.companyName
          } - ${wallet?.walletAddress}`,
          value: wallet?.walletAddress,
        };
      });
      setAddresses(addresses);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  }, []);

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const { handleSubmit, reset } = methods;

  const onResetForm = () => {
    reset(defaultValues);
  };

  const onFormSubmit = async (values: FormValues) => {
    try {
      await postCryptoTransfer({
        owner: null,
        network: values.network,
        tokenId: null,
        uintAmount: null,
        metadataUri: null,
        projectName: null,
        amount: values.amount,
        walletAddress: values.walletAddress,
        destinationAddress: values.destinationAddress,
        smartContractAddress: values.smartContractAddress,
      });
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("crypto__transfer__success__message"),
          type: "success",
        })
      );
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  const getNetWorks = useCallback(async (network): Promise<void> => {
    const res = await fetchCryptoNetworks(network);
    setProfiles(res);
  }, []);

  useEffect(() => {
    if (network) {
      getNetWorks(network);
      getCryptoWalletsManagement();
    }
  }, [network]);

  return (
    <MainTemplate>
      <QuidTitle>{t("crypto__transfer__page__title")}</QuidTitle>
      <div className={classes.container}>
        <FormProvider {...methods}>
          <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container direction={"column"} spacing={1}>
              <Grid item>
                <QuidTextField
                  name="amount"
                  label={t("crypto__amount")}
                  rules={{
                    required: t("crypto__amount__required") as string,
                  }}
                  textFieldProps={{
                    fullWidth: true,
                  }}
                  defaultValues={defaultValues}
                />
                <Grid item>
                  <QuidTextField
                    onChange={(e) => setNetwork(e.target.value)}
                    name="network"
                    rules={{
                      required: t("crypto__network__required") as string,
                    }}
                    label={t("crypto__network")}
                    defaultValues={defaultValues}
                    textFieldProps={{
                      select: true,
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>{t("values__network")}</em>
                    </MenuItem>
                    {networks?.map((network, index) => {
                      return (
                        <MenuItem key={index} value={network}>
                          {network}
                        </MenuItem>
                      );
                    })}
                  </QuidTextField>
                </Grid>
                <Grid item>
                  <QuidTextField
                    name="smartContractAddress"
                    rules={{
                      required: t(
                        "crypto__smartContractAddress__required"
                      ) as string,
                    }}
                    textFieldProps={{
                      select: true,
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    label={t("crypto__smartContractAddress")}
                    defaultValues={defaultValues}
                  >
                    <MenuItem value="">{t("crypto__currencies")}</MenuItem>
                    {profiles.map(({ currency, address }) => (
                      <MenuItem key={currency} value={address}>
                        {currency}
                      </MenuItem>
                    ))}
                  </QuidTextField>
                </Grid>
                <Grid item>
                  <QuidTextField
                    name="walletAddress"
                    rules={{
                      required: t("crypto__fromAddress__required") as string,
                    }}
                    label={t("crypto__fromAddress")}
                    defaultValues={defaultValues}
                    textFieldProps={{
                      select: true,
                      fullWidth: true,
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>{t("values__fromAddress")}</em>
                    </MenuItem>
                    {addresses?.map((address, index) => {
                      return (
                        <MenuItem key={index} value={address?.value}>
                          {address?.label}
                        </MenuItem>
                      );
                    })}
                  </QuidTextField>
                </Grid>
                <Grid item>
                  <div className={classes.checkboxWrapper}>
                    <input
                      type="checkbox"
                      onClick={() =>
                        setIsFromWalletOutside(!isFromWalletOutside)
                      }
                      checked={isFromWalletOutside}
                    />
                    <div>Outside Platform</div>
                  </div>
                  {isFromWalletOutside ? (
                    <QuidTextField
                      name="destinationAddress"
                      rules={{
                        required: t("crypto__fromAddress__required") as string,
                      }}
                      label={t("crypto__destinationAddress")}
                      defaultValues={defaultValues}
                      textFieldProps={{
                        fullWidth: true,
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                    />
                  ) : (
                    <QuidTextField
                      name="destinationAddress"
                      rules={{
                        required: t("crypto__fromAddress__required") as string,
                      }}
                      label={t("crypto__destinationAddress")}
                      defaultValues={defaultValues}
                      textFieldProps={{
                        select: true,
                        fullWidth: true,
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                    >
                      <MenuItem value="">
                        <em>{t("values__destinationAddress")}</em>
                      </MenuItem>
                      {addresses?.map((address, index) => {
                        return (
                          <MenuItem key={index} value={address?.value}>
                            {address?.label}
                          </MenuItem>
                        );
                      })}
                    </QuidTextField>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttonsWrapper}>
              <Button
                variant="contained"
                color="secondary"
                onClick={onResetForm}
                className="actions actions-approve"
              >
                {t("crypto__transfer__action__reset__transfer")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="actions actions-approve"
              >
                {t("crypto__transfer__action__submit__transfer")}
              </Button>
            </Grid>
          </form>
        </FormProvider>
      </div>
    </MainTemplate>
  );
};

export default CryptoPaymentTransfer;
