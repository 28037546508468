const IconSidebarToonie: React.FC = () => {
  return (
    <svg
      width="28"
      height="54"
      viewBox="0 0 28 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 13.9182C0 12.1671 1.40628 10.8326 3.05792 10.8326H7.43236V3.33311C7.43236 1.41899 8.91644 0 10.7356 0C12.6326 0 14.0389 1.41899 14.0389 3.33311V10.8326H24.9421C26.5937 10.8326 28 12.1671 28 13.9182C28 15.5847 26.5937 17.0037 24.9421 17.0037H14.0389V35.837C14.0389 43.584 18.4133 47.7504 24.9421 47.7504C26.5937 47.7504 28 49.1694 28 50.836C28 52.5871 26.5937 54 24.9421 54C14.7031 54 7.43236 47.0017 7.43236 35.837V17.0037H3.05792C1.40628 17.0037 0 15.5847 0 13.9182Z"
        fill="#F4B31D"
      />
    </svg>
  );
};

export default IconSidebarToonie;
