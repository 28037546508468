import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ApproveRejectBox from "components/atoms/ApproveRejectBox";
import { useRouteMatch } from "react-router";
import { ColDef } from "@material-ui/data-grid";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { getPartiesCountries } from "store/reducers/app.reducer";
import ProofOfAddressBlock from "components/molecules/verifications/ProofOfAddressBlock";
import VerificationTemplate from "templates/VerificationTemplate";
import { formatDate } from "utils";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import {
  updateDocumentDetails,
  updateDocument,
  acceptTask,
  rejectTask,
} from "api/tasks";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";
import { Grid } from "@material-ui/core";
import { acceptDocumentCustomer, rejectDocumentCustomer } from "api/customers";
import { VerificationRequest } from "entities/tasks/Common";
import { Document } from "entities/clients/DocumentEntity";
import { handleFailure } from "resHandlers";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
  notSupported: {
    fontSize: 22,
    fontWeight: 500,
    textAlign: "center",
    padding: "16px",
    color: "#fafafa",
    backgroundColor: "#FB4C4C",
  },
}));

const addressVerificationChecks = {
  DOCUMENT_READABLE: false,
  DETAILS_MATCH: false,
  POA_TYPE_ACCEPTED: false,
  ISSUED_WITHIN_3_MONTHS: false,
};

export interface IndividualDetails {
  [key: string]: string;
}

const IndividualAddressVerificationComp: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation("validations");
  const countries = useSelector(getPartiesCountries);
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
    isHistory: string;
  }>();
  const customerDetail = task?.variables?.customer;
  const customerAddressDetail = task?.variables?.address;
  const proofOfAddressDocuments = task?.documents?.filter(
    (d) => d.type === "PROOF_OF_ADDRESS"
  );
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const getCountry = (code: string) => {
    const country = countries.find((country) => country.code === code);

    return country?.text || "n/a";
  };

  if (!task) {
    return null;
  }

  const customerToPrint: IndividualDetails = {
    userId: customerDetail?.id,
    name: customerDetail?.name,
    surname: customerDetail?.surname,
    dateOfBirth: formatDate(customerDetail?.birthDate),
    countryOfBirth: getCountry(customerDetail?.countryOfBirth),
    nationality: getCountry(customerDetail?.nationality),
    "CONTACT INFORMATIONS": "",
    phone: `${customerDetail?.prefix} ${customerDetail?.mobileNumber}`,
    email: customerDetail?.email,
    "OTHER INFORMATIONS": "",
    mobileNumberVerified: customerDetail?.mobileNumberVerified ? "Yes" : "No",
    emailVerified: customerDetail?.emailVerified ? "Yes" : "No",
  };

  const customerAddressToPrint: IndividualDetails = {
    addressLine1: customerAddressDetail?.addressLine1,
    addressLine2: customerAddressDetail?.addressLine2 || "--",
    city: customerAddressDetail?.city,
    country: getCountry(customerAddressDetail.country),
    postalCode: customerAddressDetail?.postalCode,
    state: customerAddressDetail?.state,
  };

  const onClickApprove = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      proofOfAddressDocuments?.map((document?: Document) => {
        if (document) {
          acceptDocumentCustomer(
            (document.partyId as unknown) as string,
            (document.id as unknown) as string
          );
        }
      });
      await acceptTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickReject = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      proofOfAddressDocuments?.map((document?: Document) => {
        if (document) {
          rejectDocumentCustomer(
            (document.partyId as unknown) as string,
            (document.id as unknown) as string
          );
        }
      });
      await rejectTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <VerificationTemplate
      refetch={fetch}
      columns={columns}
      task={task}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {customerDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={customerToPrint}
              label="individual__details__title"
            />
          </div>
        )}
        {customerAddressDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock
              toPrint={customerAddressToPrint}
              label="individual__address__details__title"
            />
          </div>
        )}
        {proofOfAddressDocuments &&
          proofOfAddressDocuments?.map((document) => (
            <div className={classes.detailsWrapperThird}>
              <ProofOfAddressBlock
                downloadDoc={(params) =>
                  isHistory === undefined
                    ? fetchDocument({ ...params, taskId, taskKey })
                    : fetchHistoricalDocument({ ...params, taskId, taskKey })
                }
                updateDocDetails={(params) =>
                  updateDocumentDetails({ ...params, taskKey })
                }
                updateDoc={(params) => updateDocument({ ...params, taskKey })}
                refetch={refetch}
                doc={document}
                taskId={taskId}
                canReplace={isHistory === undefined && isAssignedToUser}
              />
            </div>
          ))}
      </div>
      {customerAddressDetail.country === "US" ? (
        <div className={classes.notSupported}>
          {t("country_not_supported", {
            country: customerAddressDetail.country,
          })}
        </div>
      ) : null}
      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectBox
            docs={proofOfAddressDocuments}
            task={task}
            disableAccept={customerAddressDetail.country === "US"}
            checks={addressVerificationChecks}
            accept={(request) => onClickApprove(request)}
            reject={(request) => onClickReject(request)}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task?.checks}
          status={task?.result?.status}
          reason={task?.result?.reason}
          description={task?.result?.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default IndividualAddressVerificationComp;
