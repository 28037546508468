import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { ColDef } from "@material-ui/data-grid";
import IdentityDocBlock from "components/molecules/verifications/IdentityDocBlock";
import { formatDate } from "utils";
import {
  acceptTask,
  rejectTask,
  updateDocument,
  updateDocumentDetails,
} from "api/tasks";
import { useSelector } from "react-redux";
import * as fromDataset from "store/reducers/dataset.reducer";
import VerificationTemplate from "templates/VerificationTemplate";
import {
  getPartiesCountries,
  getPartiesCountry,
} from "store/reducers/app.reducer";
import jwt_decode from "jwt-decode";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import SelfieDocBlock from "components/molecules/verifications/SelfieDocBlock";
import { IndividualDetails } from "./IndividualIdentityVerification";
import { useTranslation } from "react-i18next";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import PersonIcon from "@material-ui/icons/Person";
import WarningIcon from "@material-ui/icons/Warning";
import CakeIcon from "@material-ui/icons/Cake";
import ProofOfAddressBlock from "components/molecules/verifications/ProofOfAddressBlock";
import ApproveRejectBoxGBGPersonVerification from "components/atoms/ApproveRejectBoxGBGPersonVerification";
import { handleFailure } from "resHandlers";

const useStyles = makeStyles((theme) => ({
  mt32: {
    marginTop: 32,
  },
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
  jsonWrapper: {
    display: "flex",
    gap: "30px",
  },
  jsonItem: {
    backgroundColor: "white",
    borderRadius: "8px",
    padding: "16px",
  },
  sectionTitle: {
    color: theme.palette.secondary.main,
    textDecorationLine: "underline",
  },
  unorderedListCn: {
    margin: 0,
    paddingLeft: "16px",
    listStyleType: "none",
  },
  liContent: {
    display: "inline-flex",
    gap: "4px",
  },
  GlobalItemChecks: {
    display: "flex",
    gap: "20px",
    flexDirection: "column",
  },
  GlobalItemCheckResultCode: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  liCn: {
    "&:before": {
      display: "inline-block",
      content: '"-"',
      width: "1em",
      marginLeft: "-1em",
    },
  },
  reactJsonCn: {
    marginTop: "32px",
  },
  jsconContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    gap: "20px",
  },
  tableCn: {
    width: "100%",
    borderCollapse: "collapse",
  },
  theadCn: {
    height: "50px",
    fontWeight: 500,
    fontSize: "18px",
    backgroundColor: "#efefef",
    border: "1px solid #ddd",
    fontFamily: "Nunito,sans-serif",
  },
  thCn: {
    border: "1px solid #dddddd",
  },
  tdCn: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: "16px 0",
    border: "1px solid #dddddd",
  },
  tdResultCn: {
    padding: "8px",
    border: "1px solid #dddddd",
  },
  tdFirstItemCn: {
    fontSize: "18px",
    textAlign: "center",
    fontWeight: 500,
    border: "1px solid #dddddd",
    fontFamily: "Nunito,sans-serif",
  },
  iconCn: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "48px",
    height: "48px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
  },
  mismatch: {
    backgroundColor: "#FB4C4C",
  },
  match: {
    backgroundColor: "#22c55e",
  },
  nomatch: {
    backgroundColor: "#FB4C4C",
  },
  warning: {
    backgroundColor: "#FFCF23",
  },
  notDefined: {
    backgroundColor: "#dddddd",
  },
  resultTdCn: {
    padding: "16px 0",
    border: "1px solid #dddddd",
  },
  resultContentTd: {
    display: "flex",
    justifyContent: "center",
  },
}));

export interface IdentityDetails {
  [key: string]: string;
}

type GBGTableResultDetailsProps = {
  task: TaskDetail;
};

const PersonGBGIdentityVerification: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const classes = useStyles();
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
    isHistory: string;
  }>();

  const correctRequirementCheck = {
    POA_IS_READABLE: false,
    THE_DETAILS_MATCH: false,
    POA_TYPE_IS_ACCEPTED: false,
    POA_ISSUE_WITHIN_LAST_3_MONTHS: false,
  };

  const screeningResultSelect = [
    { value: "no_match", label: "No match" },
    { value: "false_positive", label: "False positive" },
    { value: "positive_match", label: "Positive match" },
  ];

  const taskVariables = task?.variables;
  const personDetail = taskVariables?.customer;
  const customerAddressDetail = task?.variables?.address;
  const idDocuments = task?.documents?.filter((d) => d.type === "ID_DOCUMENT");
  const selfieDocuments = task?.documents?.filter(
    (d) => d.type === "SELFIE"
  )[0];
  const proofOfAddressDocuments = task?.documents?.filter(
    (d) => d.type === "PROOF_OF_ADDRESS"
  );
  const documents = task?.documents?.filter(
    (d) => d.type === "PROOF_OF_ADDRESS"
  );
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const birthCountry = useSelector(
    (state: { dataset: fromDataset.DatasetSliceState }) =>
      getPartiesCountry(state, personDetail?.countryOfBirth)
  );
  const nationality = useSelector(
    (state: { dataset: fromDataset.DatasetSliceState }) =>
      getPartiesCountry(state, personDetail?.nationality)
  );
  const countries = useSelector(getPartiesCountries);

  const getCountry = (code: string) => {
    const country = countries.find((country) => country?.code === code);

    return country?.text || "n/a";
  };

  if (!task) {
    return null;
  }

  const toPrint: IdentityDetails = {
    personId: personDetail?.id,
    personName: personDetail?.name,
    personSurname: personDetail?.surname,
    personFiscalCode: personDetail?.fiscalCode || "n/a",
    personGender: personDetail?.gender || "n/a",
    personBirthDate: personDetail?.birthDate
      ? formatDate(personDetail.birthDate)
      : "n/a",
    personCountryOfBirth: birthCountry || "",
    personNationality: nationality || "",
    personType: taskVariables?.personType,
  };

  const customerAddressToPrint: IndividualDetails = {
    addressLine1: customerAddressDetail?.addressLine1,
    addressLine2: customerAddressDetail?.addressLine2 || "--",
    city: customerAddressDetail?.city,
    country: getCountry(customerAddressDetail?.country),
    postalCode: customerAddressDetail?.postalCode,
    state: customerAddressDetail?.state,
  };

  const handleApproveTask = async () => {
    setIsLoading(true);
    try {
      await acceptTask({ taskId, taskKey });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  const handleRejectTask = async () => {
    setIsLoading(true);
    try {
      await rejectTask({ taskId, taskKey });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <VerificationTemplate
      refetch={fetch}
      task={task}
      columns={columns}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        <div className={classes.detailsWrapperQuarter}>
          {personDetail ? (
            <DetailsBlock toPrint={toPrint} label="person_details__title" />
          ) : null}
          {customerAddressDetail ? (
            <DetailsBlock
              toPrint={customerAddressToPrint}
              label="individual__address__details__title"
            />
          ) : null}
        </div>
        {idDocuments && idDocuments?.length > 0 && (
          <div className={classes.detailsWrapperQuarter}>
            <IdentityDocBlock
              downloadDoc={(params) =>
                isHistory === undefined
                  ? fetchDocument({ ...params, taskId, taskKey })
                  : fetchHistoricalDocument({ ...params, taskId, taskKey })
              }
              updateDoc={async () => {}}
              updateDocDetails={async () => {}}
              refetch={async () => {}}
              docs={idDocuments}
              taskId={taskId}
              isAssignedToUser={false}
            />
          </div>
        )}

        {selfieDocuments && (
          <div className={classes.detailsWrapperQuarter}>
            <SelfieDocBlock
              downloadDoc={(params) =>
                isHistory === undefined
                  ? fetchDocument({ ...params, taskId, taskKey })
                  : fetchHistoricalDocument({ ...params, taskId, taskKey })
              }
              refetch={async () => {}}
              doc={selfieDocuments}
              taskId={taskId}
              isAssignedToUser={false}
              taskKey={"IdentityVerification"}
            />
          </div>
        )}
        <div className={classes.detailsWrapperQuarter}>
          {proofOfAddressDocuments?.map((document) => (
            <ProofOfAddressBlock
              downloadDoc={(params) =>
                isHistory === undefined
                  ? fetchDocument({ ...params, taskId, taskKey })
                  : fetchHistoricalDocument({ ...params, taskId, taskKey })
              }
              updateDocDetails={(params) =>
                updateDocumentDetails({ ...params, taskKey })
              }
              updateDoc={(params) => updateDocument({ ...params, taskKey })}
              refetch={refetch}
              doc={document}
              taskId={taskId}
              canReplace={isHistory === undefined && isAssignedToUser}
            />
          ))}
        </div>
        {task.variables?.gbgResult ? (
          <GBGTableResultDetails task={task} />
        ) : null}
      </div>
      {!isHistory && isAssignedToUser && (
        <ApproveRejectBoxGBGPersonVerification
          task={task}
          taskKey={taskKey}
          docs={documents}
          selectItems={screeningResultSelect}
          checkItems={correctRequirementCheck}
          accept={() => handleApproveTask()}
          reject={() => handleRejectTask()}
        />
      )}
    </VerificationTemplate>
  );
};

const GBGTableResultDetails = ({ task }: GBGTableResultDetailsProps) => {
  const { t } = useTranslation("validations");

  const classes = useStyles();

  const headerDetails = [
    t("table__header__item_check"),
    t("table__header__address_location"),
    t("table__header__surname"),
    t("table__header__forename"),
    t("table__header__dob"),
    t("table__header__warning"),
    t("table__header__pass"),
  ];

  const headerResults = [
    t("table__header__AuthenticationID"),
    t("table__header__score"),
    t("table__header__decision_band"),
    t("table__header__id"),
    t("table__header__name"),
    t("table__header__state"),
    t("table__header__country"),
  ];

  const baseGBGObj =
    task.variables?.gbgResult &&
    task.variables?.gbgResult["s:Envelope"] &&
    task.variables?.gbgResult["s:Envelope"]["s:Body"].AuthenticateSPResponse
      .AuthenticateSPResult;

  const AuthenticationID = baseGBGObj?.AuthenticationID
    ? baseGBGObj.AuthenticationID
    : "";
  const scoreGBG = baseGBGObj?.Score ? baseGBGObj.Score : "0";
  const decisionBandGBG = baseGBGObj?.BandText ? baseGBGObj.BandText : "";
  const ProfileID = baseGBGObj.ProfileID ? baseGBGObj.ProfileID : "";
  const ProfileName = baseGBGObj.ProfileName ? baseGBGObj.ProfileName : "";
  const ProfileState = baseGBGObj.ProfileState ? baseGBGObj.ProfileState : "";
  const Country = baseGBGObj.Country ? baseGBGObj.Country : "";

  const globalItemCheckResultCodes =
    baseGBGObj?.ResultCodes &&
    baseGBGObj.ResultCodes.GlobalItemCheckResultCodes;

  const detailsGBG = globalItemCheckResultCodes?.map((item: any) => {
    const firstRow = {
      itemCheck: item?.Name,
      address: item?.Address,
      surname: item?.Surname,
      forename: item?.Forename,
      dob: item?.DOB,
      alert: item?.Alert,
      pass: item?.Pass,
    };

    const matchArr =
      item?.Match?.GlobalItemCheckResultCode &&
      Array.isArray(item?.Match?.GlobalItemCheckResultCode)
        ? item?.Match?.GlobalItemCheckResultCode
        : item?.Match?.GlobalItemCheckResultCode
        ? [item?.Match?.GlobalItemCheckResultCode]
        : [];

    const mismatchArr =
      item?.Mismatch?.GlobalItemCheckResultCode &&
      Array.isArray(item?.Mismatch?.GlobalItemCheckResultCode)
        ? item?.Mismatch?.GlobalItemCheckResultCode
        : item?.Mismatch?.GlobalItemCheckResultCode
        ? [item?.Mismatch?.GlobalItemCheckResultCode]
        : [];

    const warningArr =
      item?.Warning?.GlobalItemCheckResultCode &&
      Array.isArray(item?.Warning?.GlobalItemCheckResultCode)
        ? item?.Warning?.GlobalItemCheckResultCode
        : item?.Warning?.GlobalItemCheckResultCode
        ? [item?.Warning?.GlobalItemCheckResultCode]
        : [];

    const commentArr =
      item?.Comment?.GlobalItemCheckResultCode &&
      Array.isArray(item?.Comment?.GlobalItemCheckResultCode)
        ? item?.Comment?.GlobalItemCheckResultCode
        : item?.Comment?.GlobalItemCheckResultCode
        ? [item?.Comment?.GlobalItemCheckResultCode]
        : [];

    return {
      firstRow,
      matchArr,
      mismatchArr,
      warningArr,
      commentArr,
    };
  });

  const resultsGBG = [
    AuthenticationID,
    scoreGBG,
    decisionBandGBG,
    ProfileID,
    ProfileName,
    ProfileState,
    Country,
  ];

  const getIconColor = (status: string) => {
    if (status?.toLowerCase() === "mismatch") {
      return classes.mismatch;
    } else if (status?.toLowerCase() === "match") {
      return classes.match;
    } else if (status?.toLowerCase() === "nomatch") {
      return classes.nomatch;
    } else if (status?.toLowerCase() === "na") {
      return classes.notDefined;
    } else if (status?.toLowerCase() === undefined) {
      return classes.notDefined;
    } else {
      return classes.mismatch;
    }
  };

  return (
    <>
      <table className={classes.tableCn}>
        <thead className={classes.theadCn}>
          <tr>
            {headerResults.map((label, index) => (
              <th key={`${label}-${index}`} className={classes.thCn}>
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {resultsGBG?.map((item: string) => {
              return (
                <td className={classes.resultTdCn}>
                  <div className={classes.resultContentTd}>{item}</div>
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
      <table className={classes.tableCn}>
        <thead className={classes.theadCn}>
          <tr>
            {headerDetails.map((label, index) => (
              <th key={`${label}-${index}`} className={classes.thCn}>
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {detailsGBG?.map((item: any) => {
            return (
              <>
                <tr>
                  <td className={classes.tdFirstItemCn}>
                    {item?.firstRow?.itemCheck}
                  </td>
                  <td className={classes.tdCn}>
                    <div
                      className={`${classes.iconCn} ${getIconColor(
                        item?.firstRow?.address
                      )}`}
                    >
                      <HomeIcon />
                    </div>
                  </td>
                  <td className={classes.tdCn}>
                    <div
                      className={`${classes.iconCn} ${getIconColor(
                        item?.firstRow?.surname
                      )}`}
                    >
                      <PeopleIcon />
                    </div>
                  </td>
                  <td className={classes.tdCn}>
                    <div
                      className={`${classes.iconCn} ${getIconColor(
                        item?.firstRow?.forename
                      )}`}
                    >
                      <PersonIcon />
                    </div>
                  </td>
                  <td className={classes.tdCn}>
                    <div
                      className={`${classes.iconCn} ${getIconColor(
                        item?.firstRow?.dob
                      )}`}
                    >
                      <CakeIcon />
                    </div>
                  </td>
                  <td className={classes.tdCn}>
                    <div
                      className={`${classes.iconCn} ${getIconColor(
                        item?.firstRow?.warning
                      )}`}
                    >
                      <WarningIcon />
                    </div>
                  </td>
                  <td className={classes.tdCn}>
                    <div
                      className={`${classes.iconCn} ${getIconColor(
                        item?.firstRow?.pass
                      )}`}
                    >
                      <WarningIcon />
                    </div>
                  </td>
                </tr>
                {item?.matchArr?.length > 0 && (
                  <>
                    <tr>
                      <td className={classes.tdResultCn}></td>
                      <td colSpan={6} className={classes.tdResultCn}>
                        <b>Match</b>
                      </td>
                    </tr>
                    {item?.matchArr?.map((match: any) => {
                      return (
                        <tr>
                          <td className={classes.tdResultCn}></td>
                          <td
                            className={`${classes.tdResultCn} ${classes.match}`}
                          >
                            {match?.Code}
                          </td>
                          <td
                            colSpan={5}
                            className={`${classes.tdResultCn} ${classes.match}`}
                          >
                            {match?.Description}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
                {item?.mismatchArr?.length > 0 && (
                  <>
                    <tr>
                      <td className={classes.tdResultCn}></td>
                      <td colSpan={6} className={classes.tdResultCn}>
                        <b>Mismatch</b>
                      </td>
                    </tr>
                    {item?.mismatchArr?.map((match: any) => {
                      return (
                        <tr>
                          <td className={classes.tdResultCn}></td>
                          <td
                            className={`${classes.tdResultCn} ${classes.mismatch}`}
                          >
                            {match?.Code}
                          </td>
                          <td
                            colSpan={5}
                            className={`${classes.tdResultCn} ${classes.mismatch}`}
                          >
                            {match?.Description}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
                {item?.warningArr?.length > 0 && (
                  <>
                    <tr>
                      <td className={classes.tdResultCn}></td>
                      <td colSpan={6} className={classes.tdResultCn}>
                        <b>Warning</b>
                      </td>
                    </tr>
                    {item?.warningArr?.map((match: any) => {
                      return (
                        <tr>
                          <td className={classes.tdResultCn}></td>
                          <td
                            className={`${classes.tdResultCn} ${classes.warning}`}
                          >
                            {match?.Code}
                          </td>
                          <td
                            colSpan={5}
                            className={`${classes.tdResultCn} ${classes.warning}`}
                          >
                            {match?.Description}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
                {item?.commentArr?.length > 0 && (
                  <>
                    <tr>
                      <td className={classes.tdResultCn}></td>
                      <td colSpan={6} className={classes.tdResultCn}>
                        <b>Comment</b>
                      </td>
                    </tr>
                    {item?.commentArr?.map((match: any) => {
                      return (
                        <tr>
                          <td className={classes.tdResultCn}></td>
                          <td className={`${classes.tdResultCn}`}>
                            {match?.Code}
                          </td>
                          <td colSpan={5} className={`${classes.tdResultCn}`}>
                            {match?.Description}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default PersonGBGIdentityVerification;
