import React, { useEffect, useState, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { CellParams, ColDef, PageChangeParams } from "@material-ui/data-grid";
import { format } from "date-fns";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { PartiesPagination } from "entities/accounts/Pagination";
import { useDispatch } from "react-redux";
import { NetworkFailure } from "features/core/NetworkFailure";
import { ServerFailure } from "features/core/Failure";
import promptsSlice from "store/reducers/prompts.reducer";
import { fetchAccessLog } from "api/accessLog";
import { CustomerAccessLog, AccessLog } from "entities/clients/AccessLogEntity";
import { TABLE_PAGE_SIZE } from "shared/constants";

interface AccessLogsProps {
  sessionid: string;
}

const AccessLogsList: React.FC<AccessLogsProps> = ({ sessionid }) => {
  const [loading, setLoading] = useState(false);
  const [accessLogs, setAccessLogs] = useState([] as CustomerAccessLog[]);
  const dispatch = useDispatch();
  const { t } = useTranslation("accessLog");
  const [pageNo, setPageNo] = useState(0);
  const [pagination, setPagination] = useState({} as PartiesPagination);

  useEffect(() => {
    const getAccessUserLog = async (): Promise<void> => {
      if (sessionid) {
        setLoading(true);
        try {
          const res = await fetchAccessLog({
            userId: sessionid,
            size: TABLE_PAGE_SIZE,
            page: pageNo,
          });
          setPagination(res.pagination);
          setAccessLogs(res.events);
        } catch (e: unknown) {
          const message =
            e instanceof ServerFailure
              ? (e as ServerFailure)?.error?.message
              : (e as NetworkFailure)?.message;

          dispatch(
            promptsSlice.actions.openSnackbar({
              message,
              type: "error",
            })
          );
        } finally {
          setLoading(false);
        }
      }
    };

    void getAccessUserLog();
    return (): void => {};
  }, [sessionid, pageNo]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const accessLogHeaders: ColDef[] = [
    {
      field: "id",
      headerName: t("accessLog__header_name__sessionId"),
      headerAlign: "center",
      width: 350,
    },
    {
      field: "ipAddress",
      headerName: t("accessLog__header_name__ipAddress"),
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "time",
      headerName: t("accessLog__header_name__time"),
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (param: CellParams): ReactElement => {
        const accessLog = (param?.row as unknown) as AccessLog;
        return accessLog.time ? (
          <div>{format(new Date(accessLog.time), "dd MMM yyyy, HH:mm")}</div>
        ) : (
          <div />
        );
      },
    },
    {
      field: "type",
      headerName: t("accessLog__header_name__type"),
      width: 150,
      headerAlign: "center",
      align: "center",
    },
  ];

  return (
    <QuidDataGrid
      onPageChange={onPageChange}
      rows={accessLogs || []}
      columns={accessLogHeaders}
      disableSelectionOnClick
      rowCount={pagination?.totalEntries}
      loading={loading}
    />
  );
};

export default AccessLogsList;
