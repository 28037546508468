import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useRouteMatch } from "react-router-dom";
import MainTemplate from "templates/MainTemplate";
import useVerificationColumns from "shared/hooks/useVerificationColumns";
import IndividualIdentityVerification from "components/organisms/verifications/IndividualIdentityVerification";
import IndividualAddressVerification from "components/organisms/verifications/IndividualAddressVerification";
import IndividualFinancialVerification from "components/organisms/verifications/IndividualFinancialVerification";
import IndividualFinancialVerificationAccount from "components/organisms/verifications/IndividualFinancialVerificationAccount";
import IndividualEKycVerification from "components/organisms/verifications/IndividualEKycVerification";
import CorporateIdentityVerification from "components/organisms/verifications/CorporateIdentityVerification";
import PersonAddressVerification from "components/organisms/verifications/PersonAddressVerification";
import PersonIdentityVerification from "components/organisms/verifications/PersonIdentityVerification";
import PersonEKycVerification from "components/organisms/verifications/PersonEkycVerification";
import CorporateFinancialVerificationAccount from "components/organisms/verifications/CorporateFinancialVerificationAccount";
import CheckCompanyDetails from "components/organisms/verifications/CheckCompanyDetails";
import UploadCompanyFormationConfirmation from "components/organisms/verifications/UploadCompanyFormationConfirmation";
import CorporateCheckAccountDetailsExternal from "components/organisms/verifications/CorporateCheckAccountDetailsExternal";
import CorporateCheckAccountDetailsDirect from "components/organisms/verifications/CorporateCheckAccountDetailsDirect";
import IndividualCheckAccountDetailsExternal from "components/organisms/verifications/IndividualCheckAccountDetailsExternal";
import IndividualCheckAccountDetailsDirect from "components/organisms/verifications/IndividualCheckAccountDetailsDirect";
import FourStopCorporateVerification from "components/organisms/verifications/FourStopCorporateVerification";
import FourStopIndividualVerification from "components/organisms/verifications/FourStopIndividualVerification";
import ErrorHandlerVerification from "components/organisms/verifications/ErrorHandlerVerification";
import { handleFailure } from "resHandlers";
import { fetchTask } from "services/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import FourStopIndividualQualifyDocuments from "components/organisms/verifications/FourStopIndividualQualifyDocuments";
import PersonGBGIdentityVerification from "components/organisms/verifications/PersonGBGIdentityVerification";
import PersonGBGEKYCVerification from "components/organisms/verifications/PersonGBGEKYCVerification";
import CorporateGBGIdentityVerification from "components/organisms/verifications/CorporateGBGVerification";
import Loader from "components/atoms/icons/Loader";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  loaderCn: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
}));

const ValidationTypeMatcher = () => {
  const { t } = useTranslation("validations");
  const {
    params: { taskId, taskDefinition: taskKey, history: isHistory },
  } = useRouteMatch<{
    taskId: string;
    history: string;
    taskDefinition: Task["taskDefinitionKey"];
  }>();
  const classes = useStyles();
  const [task, setTask] = useState<TaskDetail>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetch = async () => {
    try {
      const res = await fetchTask<TaskDetail>({
        taskId,
        taskKey,
        isHistory,
      });
      setTask(res);
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const [columns] = useVerificationColumns({
    detailMode: true,
    fetch,
    onUnassignRedirectTo: "/",
    isHistory,
    isTaskUnassignable: !isHistory,
  });
  const [accountsCols] = useVerificationColumns({
    detailMode: true,
    fetch,
    isTaskUnassignable: !isHistory,
    onUnassignRedirectTo: "/tasksaccounts",
    isHistory,
  });
  const [compFormCols] = useVerificationColumns({
    detailMode: true,
    fetch,
    isTaskUnassignable: !isHistory,
    onUnassignRedirectTo: "/taskscompanyformations",
    isHistory,
  });

  return (
    <>
      {isLoading ? (
        <div className={classes.loaderCn}>
          <Loader />
        </div>
      ) : (
        <MainTemplate>
          <div>
            <Typography variant="h5" className="title" align="center">
              {t(`title.${taskKey}`)}
            </Typography>
            {
              {
                IdentityVerification: (
                  <IndividualIdentityVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                CorporateIdentityVerification: (
                  <CorporateIdentityVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                PersonCorporateIdentityVerification: (
                  <PersonIdentityVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                AddressVerification: (
                  <IndividualAddressVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                PersonCorporateAddressVerification: (
                  <PersonAddressVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                EKYCVerification: (
                  <IndividualEKycVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                PersonCorporateEKYCVerification: (
                  <PersonEKycVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                FinancialVerification: (
                  <IndividualFinancialVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                FinancialVerificationAccount: (
                  <IndividualFinancialVerificationAccount
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                CorporateFinancialVerificationAccount: (
                  <CorporateFinancialVerificationAccount
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                CheckCompanyDetails: (
                  <CheckCompanyDetails
                    columns={compFormCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                UploadCompanyFormationConfirmation: (
                  <UploadCompanyFormationConfirmation
                    columns={compFormCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                CorporateCheckAccountDetailsExternal: (
                  <CorporateCheckAccountDetailsExternal
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                CorporateCheckAccountDetailsDirect: (
                  <CorporateCheckAccountDetailsDirect
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                IndividualCheckAccountDetailsExternal: (
                  <IndividualCheckAccountDetailsExternal
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                IndividualCheckAccountDetailsDirect: (
                  <IndividualCheckAccountDetailsDirect
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                fourStopCorporateVerification: (
                  <FourStopCorporateVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                fourStopIndividualVerification: (
                  <FourStopIndividualVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                fourStopIndividualDocumentQualifyVerification: (
                  <FourStopIndividualQualifyDocuments
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                corporateVerificationErrorHander: (
                  <ErrorHandlerVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                IndividualVerificationError: (
                  <ErrorHandlerVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                AccountOpeningErrorHandler: (
                  <ErrorHandlerVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                AccountCreationError: (
                  <ErrorHandlerVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                AccountOpeningError: (
                  <ErrorHandlerVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                CompanyDetailsError: (
                  <ErrorHandlerVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                CompanyFormationError: (
                  <ErrorHandlerVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                IndividualIdentityVerificationError: (
                  <ErrorHandlerVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                PersonCorporateErrorHandler: (
                  <ErrorHandlerVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                FourStopIndividualVerificationError: (
                  <ErrorHandlerVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                FourStopCorporateVerificationError: (
                  <ErrorHandlerVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                gbgCorporateVerification: (
                  <CorporateGBGIdentityVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                PersonCorporateGBGIdentityVerification: (
                  <PersonGBGIdentityVerification
                    columns={columns}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                PersonCorporateGBGEKYCVerification: (
                  <PersonGBGEKYCVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                IndividualGBGIdentityVerification: (
                  <PersonGBGIdentityVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                IndividualGBGEKYCVerification: (
                  <PersonGBGEKYCVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                IndividualGBGWebhookEKYCVerification: (
                  <PersonGBGEKYCVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                PersonCorporateGBGWebhookEKYCVerification: (
                  <PersonGBGEKYCVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                GBGVerificationError: (
                  <ErrorHandlerVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
                GBGCorporateVerificationError: (
                  <ErrorHandlerVerification
                    columns={accountsCols}
                    isHistory={isHistory}
                    task={task}
                    refetch={fetch}
                    setIsLoading={setIsLoading}
                  />
                ),
              }[taskKey]
            }
          </div>
        </MainTemplate>
      )}
    </>
  );
};

export default ValidationTypeMatcher;
