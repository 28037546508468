import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { ColDef, PageChangeParams, CellParams } from "@material-ui/data-grid";
import FullScreenModal from "components/atoms/FullScreenModal";
import CsvIcon from "components/atoms/icons/CsvIcon";
import DetailIcon from "components/atoms/icons/DetailIcon";
import SearchIcon from "components/atoms/icons/SearchIcon";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import QuidTitle from "components/atoms/QuidTitle";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getAccountsCurrencies,
  getAccountTypes,
  getAccountStatus,
} from "store/reducers/app.reducer";
import MainTemplate from "templates/MainTemplate";
import { handleFailure } from "resHandlers";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import AccountStatusBadge from "components/organisms/accounts/AccountStatusBadge";
import CreateAccountModal from "../components/organisms/accounts/CreateAccountModal";
import { TABLE_PAGE_SIZE } from "shared/constants";
import useCsvDownloadHandler from "../shared/hooks/useCsvDownloadHandler";
import DetailButton from "components/atoms/DetailButton";
import { downloadBalancesCsv } from "services/accounts";
import { Pagination } from "entities/accounts/Pagination";
import {
  AccountDataset,
  AccountDatasetCurrency,
} from "entities/accounts/AccountDataset";
import { Balance } from "entities/accounts/Balance";
import Loader from "components/atoms/icons/Loader";
import { fetchCustomerWallets } from "api/customers";
import { RouteComponentProps } from "react-router";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  accountsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },

  filterContainer: {
    display: "flex",
    flexDirection: "row",
  },
  flex1: {
    flex: 1,
  },
  flex05: {
    flex: 0.5,
  },
  flexSpace: {
    flex: 0.1,
  },
  flexCsv: {
    flex: 0.1,
  },
  addAccountBtn: {
    height: "100%",
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  taskLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
}));

const CustomerWallets: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("account");
  const id: number = +match.params.id;
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balances, setBalances] = useState([] as Balance[]);
  const [pageNo, setPageNo] = useState(0);
  const [pagination, setPagination] = useState({} as Pagination);
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    type: "",
    currency: "",
  });

  const currencies = useSelector(getAccountsCurrencies);
  const accountTypes = useSelector(getAccountTypes);
  const accountStatus = useSelector(getAccountStatus);

  const columns: ColDef[] = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "account_name",
      headerName: t("listTable__header_name__accountHolder"),
      flex: 1,
    },
    {
      field: "account_type",
      headerName: t("listTable__header_name__accountType"),
      flex: 1,
    },

    {
      field: "legal_entity",
      flex: 1,
      headerName: t("listTable__header_name__customer_type"),
    },
    {
      field: "account_number",
      flex: 1,
      headerName: t("listTable__header_name__account"),
      valueFormatter: (values: CellParams) =>
        `${values?.row?.account_id ?? "n/a"} ${
          values?.row?.first_name || "n/a"
        } ${values?.row?.last_name || "n/a"}`,
    },
    {
      field: "currency",
      flex: 1,
      headerName: t("listTable__header_name__currency"),
    },
    {
      field: "current_amount",
      flex: 1,
      headerName: t("listTable__header_name__totalBalance"),
      valueFormatter: (values: CellParams) =>
        MoneyFormatter(values?.row?.current_amount, values?.row?.currency),
    },
    {
      field: "available_amount",
      flex: 1,
      headerName: t("listTable__header_name__availableBalance"),
      valueFormatter: (values: CellParams) =>
        MoneyFormatter(values?.row?.available_amount, values?.row?.currency),
    },
    {
      field: "status",
      flex: 1.2,
      headerName: t("listTable__header_name__status"),
      renderCell: (params) => (
        <>
          <AccountStatusBadge status={params?.row?.status} />
          {params?.row?.status}
        </>
      ),
    },
    {
      field: "details",
      flex: 1,
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => {
        const balanceId = params?.row?.balance_id as number;
        if (!balanceId) {
          return <DetailIcon />;
        }
        return (
          <DetailButton
            to={`/accounts/${params.row.account_id}/balances/${params.row.balance_id}`}
          />
        );
      },
    },
  ];

  useEffect(() => {
    const fetchAllBalances = async () => {
      setLoading(true);
      try {
        const balanceRes = await fetchCustomerWallets({
          partyId: id,
          size: TABLE_PAGE_SIZE,
          page: pageNo,
        });
        const { pagination, balances } = balanceRes;
        setPagination(pagination);
        setBalances(balances);
      } catch (err: any) {
        handleFailure(err);
      } finally {
        setLoading(false);
      }
    };

    void fetchAllBalances();
  }, [pageNo, filter]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const { onCsvDownload, documentLoading } = useCsvDownloadHandler({
    download: () => downloadBalancesCsv({ fileName: "balances" }),
  });

  return (
    <>
      <>
        {documentLoading && (
          <div className={classes.downloadFile}>
            <Loader />
          </div>
        )}
      </>
      <MainTemplate>
        <FullScreenModal open={modalOpen} onClose={onCloseModal}>
          <CreateAccountModal onClose={onCloseModal} />
        </FullScreenModal>
        <div className={classes.accountsContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>
                {t("transactionTable__header_name__balances")}
              </QuidTitle>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <TextField
                label={t("filter__search")}
                variant="outlined"
                fullWidth
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({ ...state, search: e.target.value }))
                }
                value={filter.search}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className={classes.searchBox}>
                        <SearchIcon color="#ffffff" />
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={t("filter__accountType")}
                variant="outlined"
                select
                fullWidth
                value={filter.type}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({ ...state, type: e.target.value }))
                }
              >
                <MenuItem value="">{t("filter__accountType")}</MenuItem>
                {accountTypes?.map((option: AccountDataset, index) => (
                  <MenuItem key={`${option}-${index}`} value={option.code}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={t("filter__currency")}
                variant="outlined"
                select
                fullWidth
                value={filter.currency}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({ ...state, currency: e.target.value }))
                }
              >
                <MenuItem value="">{t("filter__currency")}</MenuItem>
                {currencies?.map(
                  (currency: AccountDatasetCurrency, index: number) => (
                    <MenuItem
                      key={`${currency.code}-${index}`}
                      value={currency.code}
                    >
                      {currency?.text}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={t("filter__status")}
                variant="outlined"
                select
                fullWidth
                value={filter.status}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({
                    ...state,
                    status: e.target.value,
                  }))
                }
              >
                <MenuItem value="">{t("filter__status")}</MenuItem>
                {accountStatus?.map((option: AccountDataset, index: number) => (
                  <MenuItem key={`${option}-${index}`} value={option.code}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={1}>
              <Button
                variant="outlined"
                className={classes.csvBtn}
                onClick={() => onCsvDownload()}
              >
                <CsvIcon />
              </Button>
            </Grid>
          </Grid>
          <QuidDataGrid
            onPageChange={onPageChange}
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={balances}
            rowCount={pagination?.total_entries}
          />
        </div>
      </MainTemplate>
    </>
  );
};

export default CustomerWallets;
