import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { PageChangeParams } from "@material-ui/data-grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MainTemplate from "templates/MainTemplate";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { fetchTasks } from "store/actions/tasks";
import {
  getTaskRequestStatus,
  getTasks,
  getTasksTotal,
} from "store/reducers/app.reducer";

import { TABLE_PAGE_SIZE } from "shared/constants";
import QuidTitle from "components/atoms/QuidTitle";
import useVerificationColumns from "shared/hooks/useVerificationColumns";
import { reqStatus } from "shared/entities/reqStatus";
import {
  Task,
  DashboardTaskTypes,
  TaskCategories,
} from "entities/tasks/TaskEntity";
import { GenericObject } from "entities/tasks/Identity";

const useStyles = makeStyles((theme) => ({
  taskContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    flexDirection: "column",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  filterContainer: {
    marginTop: 30,
    color: "#37383C",
  },
  root: {
    color: "#37383C",
    marginTop: 30,
    "& .MuiDataGrid": {
      "&-colCellWrapper": {
        height: "40px",
      },
      "&-row": {
        backgroundColor: "#FFFFFF",
        borderBottom: "2px solid #FFFFFF",
        borderTop: "1px solid #FFFFFF",
      },
      "&-columnsContainer": {
        backgroundColor: theme.palette.secondary.main,
        color: "#FFFFFF",
      },
      "&-iconSeparator": {
        display: "none",
      },
    },
  },
}));

const TaskList: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("dashboard");
  const dispatch = useDispatch();
  const tasks: Task[] = useSelector((state: GenericObject) =>
    getTasks(state, "tasks/fetchTasksSearch")
  );
  const status = useSelector((state: GenericObject) =>
    getTaskRequestStatus(state, "tasks/fetchTasksSearch")
  );
  const total: number = useSelector((state: GenericObject) =>
    getTasksTotal(state, "tasks/fetchTasksSearch")
  );
  const [pageNo, setPageNo] = useState(0);
  const [filter, setFilter] = useState({
    customerType: "",
    name: "",
    search: "",
    dateFrom: "",
    dateTo: "",
    taskType: "",
  });

  const customerTypeKeys: DashboardTaskTypes[] = [
    { code: "INDIVIDUAL", text: t("individual__task") },
    { code: "CORPORATE", text: t("corporate__task") },
  ];

  const taskDefinitionKeys: DashboardTaskTypes[] = [
    { code: "Identity Verification", text: t("identity__verification") },
    { code: "Address Verification", text: t("address__verification") },
    { code: "EKYC Verification", text: t("ekyc__verification") },
    { code: "Financial Verification", text: t("financial__verification") },
    {
      code: "Financial Verification Account",
      text: t("financial__verification__account"),
    },
    {
      code: "Corporate Identity Verification",
      text: t("corporate__identity__verification"),
    },
    {
      code: "Person Corporate Identity Verification",
      text: t("person__corporate__identity_verification"),
    },
    {
      code: "Person Corporate Address Verification",
      text: t("person__corporate__address_verification"),
    },
    {
      code: "Person Corporate EKYC Verification",
      text: t("person__corporate__ekyc_verification"),
    },
    {
      code: "Corporate Financial Verification Account",
      text: t("corporate__financial__verification_account"),
    },
    {
      code: "FourStop Corporate Verification",
      text: t("fourstop__corporate__verification"),
    },
    {
      code: "FourStop Individual Verification",
      text: t("fourstop__individual__verification"),
    },
  ];

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const fetchAllTasks = () =>
    dispatch(
      fetchTasks({
        params: {
          ...(filter?.customerType && { customerType: filter?.customerType }),
          ...(filter?.search && { searchField: filter?.search }),
          ...(filter?.taskType && { taskName: filter?.taskType }),
          ...(filter?.dateTo && { dateTo: filter.dateTo }),
          ...(filter?.dateFrom && { dateFrom: filter.dateFrom }),
          pageNo,
          pageSize: TABLE_PAGE_SIZE,
        },
        category: TaskCategories.TASK_SEARCH,
      }) as any
    );

  const [columns] = useVerificationColumns({
    pageNo,
    fetch: fetchAllTasks,
    isHistory: undefined,
  });

  useEffect(() => {
    fetchAllTasks();
  }, [
    pageNo,
    filter.customerType,
    filter.dateFrom,
    filter.dateTo,
    filter.taskType,
  ]);

  const isLoading = status === reqStatus.PENDING;
  return (
    <MainTemplate>
      <div className={classes.taskContainer}>
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <QuidTitle>{t("title__tasks")}</QuidTitle>
          </Grid>
        </Grid>
        <div className="filterContainer">
          <Grid container spacing={4}>
            <Grid item xs={9}></Grid>
            <Grid item xs={3}>
              <TextField
                label={t("filter__search")}
                variant="outlined"
                fullWidth
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({ ...state, search: e.target.value }))
                }
                onBlur={() => fetchAllTasks()}
                value={filter.search}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <div className={classes.searchBox}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M15.7372 14.4753L12.7159 11.463C13.6907 10.2211 14.2196 8.68756 14.2176 7.10882C14.2176 5.70283 13.8007 4.32841 13.0196 3.15937C12.2385 1.99033 11.1282 1.07918 9.82925 0.54113C8.53028 0.00308012 7.10094 -0.137698 5.72196 0.136597C4.34299 0.410893 3.07631 1.08794 2.08213 2.08213C1.08794 3.07631 0.410893 4.34299 0.136597 5.72196C-0.137698 7.10094 0.00308012 8.53028 0.54113 9.82925C1.07918 11.1282 1.99033 12.2385 3.15937 13.0196C4.32841 13.8007 5.70283 14.2176 7.10882 14.2176C8.68756 14.2196 10.2211 13.6907 11.463 12.7159L14.4753 15.7372C14.5579 15.8204 14.6562 15.8865 14.7645 15.9317C14.8728 15.9768 14.9889 16 15.1062 16C15.2236 16 15.3397 15.9768 15.448 15.9317C15.5563 15.8865 15.6545 15.8204 15.7372 15.7372C15.8204 15.6545 15.8865 15.5563 15.9317 15.448C15.9768 15.3397 16 15.2236 16 15.1062C16 14.9889 15.9768 14.8728 15.9317 14.7645C15.8865 14.6562 15.8204 14.5579 15.7372 14.4753ZM1.77721 7.10882C1.77721 6.05433 2.0899 5.02352 2.67575 4.14674C3.26159 3.26996 4.09428 2.58659 5.0685 2.18305C6.04273 1.77952 7.11474 1.67393 8.14897 1.87965C9.1832 2.08538 10.1332 2.59316 10.8788 3.3388C11.6245 4.08444 12.1323 5.03444 12.338 6.06868C12.5437 7.10291 12.4381 8.17492 12.0346 9.14914C11.6311 10.1234 10.9477 10.9561 10.0709 11.5419C9.19413 12.1277 8.16332 12.4404 7.10882 12.4404C5.69479 12.4404 4.33867 11.8787 3.3388 10.8788C2.33893 9.87897 1.77721 8.52285 1.77721 7.10882Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
              <TextField
                label={t("filter__customer_types")}
                variant="outlined"
                select
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({
                    ...state,
                    customerType: e.target.value,
                  }))
                }
                fullWidth
                value={filter.customerType}
              >
                <MenuItem value="">
                  <em>{t("filter__allRequests")}</em>
                </MenuItem>
                {customerTypeKeys?.map(
                  (value: DashboardTaskTypes, index: number) => (
                    <MenuItem key={`${value}-${index}`} value={value.code}>
                      {`${t(value.text)}`}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t("filter__allRequests")}
                variant="outlined"
                select
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({
                    ...state,
                    taskType: e.target.value,
                  }))
                }
                fullWidth
                value={filter.taskType}
              >
                <MenuItem value="">
                  <em>{t("filter__allRequests")}</em>
                </MenuItem>
                {taskDefinitionKeys?.map(
                  (value: DashboardTaskTypes, index: number) => (
                    <MenuItem key={`${value}-${index}`} value={value.code}>
                      {`${t(value.text)}`}
                    </MenuItem>
                  )
                )}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={t("filter__dateFrom")}
                variant="outlined"
                type="date"
                fullWidth
                value={filter.dateFrom}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({
                    ...state,
                    dateFrom: e.target.value,
                  }))
                }
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label={t("filter__dateTo")}
                variant="outlined"
                type="date"
                fullWidth
                value={filter.dateTo}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFilter((state) => ({
                    ...state,
                    dateTo: e.target.value,
                  }))
                }
              />
            </Grid>
          </Grid>
        </div>
        <QuidDataGrid
          onPageChange={onPageChange}
          className={classes.root}
          loading={isLoading}
          rows={tasks}
          columns={columns}
          disableSelectionOnClick
          hideHeader
          rowCount={total}
        />
      </div>
    </MainTemplate>
  );
};

export default TaskList;
