import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import {
  cryptoFlowGet,
  fetchCryptoinfo,
  fetchCryptoSmartContractBySmartContractId,
  fetchCryptoWalletManagementByPartyId,
  fetchCryptoWalletsManagement,
} from "@portit/core/api/Crypto";
import {
  FlowResponse,
  SmartContractBalanceDetailResponse,
  WalletDetailResponse,
} from "@portit/core/entities/Crypto";
import Loader from "components/atoms/icons/Loader";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { handleFailure } from "resHandlers";
import MainTemplate from "templates/MainTemplate";

const useStyles = makeStyles((theme) => ({
  downloadFile: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 15,
  },
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  mt20: {
    marginTop: 20,
  },
  mt35: {
    marginTop: 35,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
  },
  jsonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "stretch",
    alignItems: " center",
    marginTop: "20px",
    width: "100%",
    gap: "80px",
  },
}));

const CryptoBalanceDetails: React.FC<
  RouteComponentProps<{
    partyId: string;
    walletId: string;
    smartContractId: string;
  }>
> = ({ match }) => {
  const { t } = useTranslation("crypto");

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState<FlowResponse[]>([]);
  const [details, setDetails] = useState<WalletDetailResponse>();
  const [dataListReverse, setDataListReverse] = useState<FlowResponse[]>([]);
  const [smartContract, setSmartContract] = useState(
    {} as SmartContractBalanceDetailResponse
  );

  const walletId: number = +match.params.walletId;
  const currency: string = smartContract?.currency;
  const partyId = parseInt(match.params.partyId, 10);
  const smartContractId: number = +match.params.smartContractId;

  const isAllowedCryptoFlow = (currency: string) => {
    if (currency?.startsWith("es") || currency?.startsWith("str")) {
      return true;
    } else {
      return false;
    }
  };

  const getCryptoSmartContract = useCallback(async () => {
    try {
      if (smartContractId && walletId) {
        //  get Smart contract info
        const walletRes = await fetchCryptoSmartContractBySmartContractId(
          walletId,
          smartContractId
        );
        setSmartContract(walletRes);
      }
    } catch (e: any) {
      handleFailure(e);
    }
  }, [walletId, smartContractId]);

  const getCryptoWalletByPartyId = useCallback(async () => {
    try {
      if (partyId) {
        //  get details of the current wallet
        const res = await fetchCryptoWalletManagementByPartyId(partyId);
        setDetails(res);
      }
    } catch (e: any) {
      handleFailure(e);
    }
  }, [partyId]);

  const fetchCryptoFlow = useCallback(
    async (wallet, cryptoInfo) => {
      try {
        if (
          details &&
          wallet &&
          cryptoInfo &&
          wallet.walletAddress !== details.walletAddress
        ) {
          const [walletRes, walletReverseRes] = await Promise.all([
            cryptoFlowGet(
              wallet.walletAddress,
              details?.walletAddress,
              cryptoInfo.address,
              wallet.network
            ),
            cryptoFlowGet(
              details?.walletAddress,
              wallet.walletAddress,
              cryptoInfo.address,
              wallet.network
            ),
          ]);

          setDataList((prev) => [...prev, walletRes]);
          setDataListReverse((prev) => [...prev, walletReverseRes]);
        }
      } catch (e: any) {
        handleFailure(e);
      }
    },
    [details]
  );

  const getWalletsManagementJsonDetails = useCallback(async () => {
    setLoading(true);
    try {
      if (details && isAllowedCryptoFlow(currency)) {
        const [wallets, cryptoInfo] = await Promise.all([
          fetchCryptoWalletsManagement({
            page: 0,
            size: 9999,
          }),
          fetchCryptoinfo(smartContractId),
        ]);

        if (wallets.wallets && cryptoInfo) {
          const allPromises = wallets.wallets.map((wallet) => {
            return fetchCryptoFlow(wallet, cryptoInfo);
          });

          await Promise.all(allPromises);
        }
      }
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setLoading(false);
    }
  }, [details, currency]);

  const cryptoSmartcontractDetailsToPrint: SmartContractBalanceDetailResponse = {
    currency: currency || "n/a",
    network: smartContract?.network || "n/a",
    smartContractId: smartContract?.smartContractId || 0,
    tokenIds: JSON.stringify(smartContract?.tokenIds),
  };

  useEffect(() => {
    getCryptoWalletByPartyId();
    getCryptoSmartContract();
  }, []);

  return (
    <>
      {loading && (
        <div className={classes.downloadFile}>
          <Loader />
        </div>
      )}
      <MainTemplate>
        <div className={classes.modalBody}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.title}>
                {t("crypto_payment__details__title")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            justify="center"
            className={classes.mt20}
          >
            <Grid item xs={12}>
              <DetailsBlock
                label={t("details__crypto_payment__details")}
                toPrint={cryptoSmartcontractDetailsToPrint}
              />
            </Grid>
            {process.env.REACT_APP_PROJECT === "strfiinvest" && (
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                className={classes.mt20}
              >
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => getWalletsManagementJsonDetails()}
                  >
                    {t("balance_details__flow")}
                  </Button>{" "}
                </Grid>
              </Grid>
            )}
            {!loading && dataList && dataListReverse && (
              <Grid className={classes.mt20} item xs={12}>
                <div className={classes.jsonContainer}>
                  <Grid item xs={6}>
                    <Grid container direction={"column"} alignItems={"center"}>
                      {dataList?.map((item, index) => {
                        return (
                          <DetailsBlock
                            key={`flow-details-${index}`}
                            label="flow__details__menu__title"
                            toPrint={item}
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container direction={"column"} alignItems={"center"}>
                      {dataListReverse.map((item) => {
                        return (
                          <DetailsBlock
                            label="flow__details__menu__title"
                            toPrint={item}
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </MainTemplate>
    </>
  );
};

export default CryptoBalanceDetails;
