import React from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useRouteMatch } from "react-router";
import { ColDef } from "@material-ui/data-grid";
import DocumentsProofBlock from "components/molecules/verifications/DocumentsProofBlock";
import VerificationTemplate from "templates/VerificationTemplate";
import jwt_decode from "jwt-decode";
import { acceptTask, rejectTask, TaskDocumentQualify } from "api/tasks";
import ApproveRejectSimpleBox from "components/atoms/ApproveRejectBoxSimple";
import { Document } from "entities/clients/DocumentEntity";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";
import { handleFailure } from "resHandlers";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const FourStopIndividualQualifyDocuments: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const classes = useStyles();
  const { t } = useTranslation("validations");
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
  }>();
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const dispatch = useDispatch();
  const processId = task?.task?.processInstance || "";
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const QualifyDocument = async (
    processId: string,
    documentId: number,
    qualify: "OCR" | "MANUAL"
  ) => {
    try {
      await TaskDocumentQualify({
        processId,
        documentId,
        qualify,
      });
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: `${t(`qualify.updated`) + qualify}`,
          type: "success",
        })
      );
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  if (!task) {
    return null;
  }

  const handleApproveTask = async () => {
    setIsLoading(true);
    try {
      await acceptTask({ taskId, taskKey });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  const handleRejectTask = async () => {
    setIsLoading(true);
    try {
      await rejectTask({ taskId, taskKey });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(true);
    }
  };

  return (
    <VerificationTemplate
      refetch={refetch}
      columns={columns}
      task={task}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {task?.documents &&
          task?.documents?.map((identityDocument: Document) => (
            <div className={classes.detailsWrapperThird}>
              <DocumentsProofBlock
                title={t("document__documentAccountUsage__title")}
                taskKey={taskKey}
                inputId="doc-pobt"
                refetch={refetch}
                doc={identityDocument}
                taskId={taskId}
                isHistory={isHistory ? true : false}
                canReplace={false}
              />
              <div className={classes.buttons}>
                <Button
                  onClick={() =>
                    QualifyDocument(processId, identityDocument.id, "MANUAL")
                  }
                  variant="contained"
                  color="primary"
                >
                  {t("button.manual")}
                </Button>
                <Button
                  onClick={() =>
                    QualifyDocument(processId, identityDocument.id, "OCR")
                  }
                  variant="contained"
                  color="primary"
                >
                  {t("button.ocr")}
                </Button>
              </div>
            </div>
          ))}
      </div>
      {!isHistory && isAssignedToUser && (
        <ApproveRejectSimpleBox
          accept={() => handleApproveTask()}
          reject={() => handleRejectTask()}
        />
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task.checks}
          status={task.result.status}
          reason={task.result.reason}
          description={task.result.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default FourStopIndividualQualifyDocuments;
