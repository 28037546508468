import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ApproveRejectBox from "components/atoms/ApproveRejectBox";
import { useRouteMatch } from "react-router";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { ColDef } from "@material-ui/data-grid";
import IdentityDocBlock from "components/molecules/verifications/IdentityDocBlock";
import { formatDate } from "utils";
import {
  acceptTask,
  rejectTask,
  updateDocument,
  updateDocumentDetails,
} from "api/tasks";
import { useSelector } from "react-redux";
import * as fromDataset from "store/reducers/dataset.reducer";
import VerificationTemplate from "templates/VerificationTemplate";
import { getPartiesCountry } from "store/reducers/app.reducer";
import jwt_decode from "jwt-decode";
import { fetchDocument, fetchHistoricalDocument } from "services/tasks";
import { TaskDetail } from "entities/tasks/TaskDetailEntity";
import { Task } from "entities/tasks/TaskEntity";
import ApproveRejectKycBox from "components/atoms/ApproveRejectKycBox";
import { Grid } from "@material-ui/core";
import { VerificationRequest } from "entities/tasks/Common";
import { Document } from "entities/clients/DocumentEntity";
import {
  acceptDocumentCompanies,
  cloneDocumentsFromDirectorToUbo,
  rejectDocumentCompanies,
} from "api/companies";
import SelfieDocBlock from "components/molecules/verifications/SelfieDocBlock";
import { handleFailure } from "resHandlers";

const useStyles = makeStyles(() => ({
  topContainer: {
    backgroundColor: "#FAFAFA",
    display: "flex",
    flexWrap: "wrap",
    padding: "50px 30px",
  },
  detailsWrapperQuarter: {
    width: "25%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperThird: {
    width: "33%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperHalf: {
    width: "50%",
    padding: "0px 20px",
    marginBottom: 50,
    boxSizing: "border-box",
  },
  detailsWrapperFull: {
    width: "100%",
    padding: "0px 20px",
    boxSizing: "border-box",
  },
}));

const identityVerificationChecks = {
  DOCUMENT_READABLE: false,
  DOCUMENT_VALID: false,
  DETAILS_MATCH: false,
  DOCUMENT_GENUINE: false,
};

export interface IdentityDetails {
  [key: string]: string;
}

const PersonIdentityVerificationComp: React.FC<{
  columns: ColDef[];
  isHistory: string;
  task: TaskDetail | undefined;
  refetch: () => Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ columns, isHistory, task, refetch, setIsLoading }) => {
  const classes = useStyles();
  const {
    params: { taskId, taskDefinition: taskKey },
  } = useRouteMatch<{
    taskId: string;
    taskDefinition: Task["taskDefinitionKey"];
    isHistory: string;
  }>();
  const taskVariables = task?.variables;
  const personDetail = taskVariables?.person;
  const idDocuments = task?.documents?.filter((d) => d.type === "ID_DOCUMENT");
  const selfieDocuments = task?.documents?.filter(
    (d) => d.type === "SELFIE"
  )[0];
  const token: { preferred_username: string } = jwt_decode(
    JSON.parse(localStorage.getItem("_quid:token") || "{}").access_token
  );
  const isAssignedToUser = task?.task?.assignee === token.preferred_username;

  const birthCountry = useSelector(
    (state: { dataset: fromDataset.DatasetSliceState }) =>
      getPartiesCountry(state, personDetail?.countryOfBirth)
  );
  const nationality = useSelector(
    (state: { dataset: fromDataset.DatasetSliceState }) =>
      getPartiesCountry(state, personDetail?.nationality)
  );

  const onClickApprove = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      idDocuments?.map((document?: Document) => {
        if (document) {
          acceptDocumentCompanies(
            (document.partyId as unknown) as string,
            (document.id as unknown) as string
          );
        }
      });
      if (taskVariables?.uboId) {
        cloneDocumentsFromDirectorToUbo(
          taskVariables.personid,
          taskVariables.uboId
        );
      }
      await acceptTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickReject = async (request: VerificationRequest) => {
    setIsLoading(true);
    try {
      idDocuments?.map((document?: Document) => {
        if (document) {
          rejectDocumentCompanies(
            (document.partyId as unknown) as string,
            (document.id as unknown) as string
          );
        }
      });
      await rejectTask({ taskId, taskKey, request });
    } catch (e: any) {
      handleFailure(e);
    } finally {
      setIsLoading(false);
    }
  };

  if (!task) {
    return null;
  }

  const toPrint: IdentityDetails = {
    personId: personDetail?.id,
    personName: personDetail?.name,
    personSurname: personDetail?.surname,
    personFiscalCode: personDetail?.fiscalCode || "n/a",
    personGender: personDetail?.gender || "n/a",
    personBirthDate: personDetail?.birthDate
      ? formatDate(personDetail.birthDate)
      : "n/a",
    personCountryOfBirth: birthCountry || "",
    personNationality: nationality || "",
    personType: taskVariables?.personType,
  };

  return (
    <VerificationTemplate
      refetch={fetch}
      task={task}
      columns={columns}
      isHistory={isHistory ? true : false}
    >
      <div className={classes.topContainer}>
        {personDetail && (
          <div className={classes.detailsWrapperThird}>
            <DetailsBlock toPrint={toPrint} label="person_details__title" />
          </div>
        )}
        {idDocuments?.length && (
          <div className={classes.detailsWrapperThird}>
            <IdentityDocBlock
              downloadDoc={(params) =>
                isHistory === undefined
                  ? fetchDocument({ ...params, taskId, taskKey })
                  : fetchHistoricalDocument({ ...params, taskId, taskKey })
              }
              updateDoc={(params) => updateDocument({ ...params, taskKey })}
              updateDocDetails={(params) =>
                updateDocumentDetails({ ...params, taskKey })
              }
              refetch={refetch}
              docs={idDocuments}
              taskId={taskId}
              isAssignedToUser={isAssignedToUser}
            />
          </div>
        )}
        {selfieDocuments && (
          <div className={classes.detailsWrapperThird}>
            <SelfieDocBlock
              downloadDoc={(params) =>
                isHistory === undefined
                  ? fetchDocument({ ...params, taskId, taskKey })
                  : fetchHistoricalDocument({ ...params, taskId, taskKey })
              }
              refetch={refetch}
              doc={selfieDocuments}
              taskId={taskId}
              isAssignedToUser={isAssignedToUser}
              taskKey={"IdentityVerification"}
            />
          </div>
        )}
      </div>
      {!isHistory && isAssignedToUser && (
        <Grid container>
          <ApproveRejectBox
            docs={idDocuments}
            checks={identityVerificationChecks}
            task={task}
            accept={(request) => onClickApprove(request)}
            reject={(request) => onClickReject(request)}
          />
        </Grid>
      )}
      {isHistory && (
        <ApproveRejectKycBox
          checks={task.checks}
          status={task.result.status}
          reason={task.result.reason}
          description={task.result.description}
        />
      )}
    </VerificationTemplate>
  );
};

export default PersonIdentityVerificationComp;
