import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";
import { useEffect, useState } from "react";
import { fetchOffersList } from "api/offers";

interface DashboardUserKPIProps {}

const DashboardOffersKPI: React.FC<DashboardUserKPIProps> = () => {
  const [totalOffers, setTotalOffers] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const getTotalOffers = async (): Promise<void> => {
      try {
        const offersListRes = await fetchOffersList({
          page: 1,
          size: 1,
        });
        setTotalOffers(offersListRes?.pagination?.total_entries);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    };
    void getTotalOffers();
  }, []);
  return <div>{totalOffers}</div>;
};

export default DashboardOffersKPI;
