import {
  BeneficiaryResponse,
  FeeResponse,
  GetBeneficiariesParams,
  GetFeesParams,
  GetValidationParams,
  PaymentDetail,
  ValidationResponse,
} from "entities/accounts/Payment";
import { CreateInternalTransactionParams } from "entities/payments/InternalTransfer";
import { handleApiClientError } from "utils";
import { apiClient } from "@portit/core/utils/api/apiClient";

export async function createInternalTransaction(
  params: CreateInternalTransactionParams
): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/accounts/v1/transfers`,
      "account",
      { ...params }
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
export async function getBeneficiariesByBalanceId(
  params: GetBeneficiariesParams
): Promise<BeneficiaryResponse> {
  try {
    const res = await apiClient.get<BeneficiaryResponse>(
      `/accounts/v1/beneficiaries/accounts/balance/${params.balance_id}`,
      "account",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function validatePayment(
  params: GetValidationParams
): Promise<ValidationResponse> {
  try {
    const res = await apiClient.post<ValidationResponse>(
      `/accounts/v1/payments/validate`,
      "account",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function performPayment(
  params: GetValidationParams
): Promise<PaymentDetail> {
  try {
    const res = await apiClient.post<PaymentDetail>(
      `/accounts/v1/payments/bo`,
      "account",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}

export async function getFees(params: GetFeesParams): Promise<FeeResponse> {
  try {
    const res = await apiClient.get<FeeResponse>(
      `/accounts/v1/fee`,
      "account",
      params
    );
    return res;
  } catch (err: any) {
    const e = handleApiClientError(err);
    throw e;
  }
}
