import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { RouteComponentProps, useHistory } from "react-router-dom";
import QuidTitle from "components/atoms/QuidTitle";
import { handleFailure } from "resHandlers";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { formatDate } from "utils";
import clsx from "clsx";
import { deleteOffers, fetchOfferByOfferId } from "api/offers";
import {
  Offer,
  OfferDetailsToPrint,
  OfferMerchantDetailsToPrint,
} from "entities/offer/OfferEntity";
import OfferImage from "components/molecules/verifications/OfferImage";
import {
  fetchOffersBlockByOfferId,
  fetchOffersUnblockByOfferId,
} from "@portit/core/api/Offers";

const useStyles = makeStyles((theme) => ({
  accountsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
  },
  status: {
    display: "flex",
    fontSize: 14,
    flexDirection: "row",
    paddingRight: 24,
  },
  infoContainer: {
    width: "100%",
    height: 60,
    display: "flex",
    fontSize: 18,
    flexDirection: "row",
    background: "#F3F3F3",
    alignItems: "center",
    color: "#37383C",
    justifyContent: "space-between",
    "& a": {
      color: theme.palette.secondary.main,
      textDecoration: "underline",
      paddingLeft: 24,
    },
    "& span": {
      paddingLeft: 24,
    },
  },
  detailContainer: {
    background: "#FAFAFA",
    width: "100%",
    padding: "20px 0px",
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  flex1: {
    flex: 1,
  },
  flexContainer: {
    display: "flex",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexColumn: {
    flexDirection: "column",
  },
  mt16: {
    marginTop: 16,
  },
  pl24: {
    paddingLeft: 24,
  },
  pr8: {
    paddingRight: 8,
  },
  pl8: {
    paddingLeft: 8,
  },
  pr24: {
    paddingRight: 24,
  },
  mt32: {
    marginTop: 32,
  },
  mb32: {
    marginBottom: 32,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  red: {
    background: "#FB4C4C",
  },
  green: {
    background: "#94E60F",
  },
  fullHeight: {
    height: "100%",
  },
  actionBtn: {
    height: 52,
    "&.MuiButton-root": {
      width: "90%",
    },
  },
  taskLink: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  buttonsContainer: {
    textAlign: "center",
    justifyContent: "center",
  },
  modalButtonContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "24px 0 16px 0",
  },
  buttonMargin: {
    "&--left": {
      marginLeft: 16,
    },
    "&--right": {
      marginRight: 16,
    },
  },
}));

const OfferDetail: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("offer");
  const history = useHistory();
  const offerId = parseInt(match.params.id, 10);
  const [modalOpen, setModalOpen] = useState(false);
  const [details, setDetails] = useState({} as Offer);

  const offerDetailsToPrint: OfferDetailsToPrint = {
    offerId: details?.offerId || "n/a",
    offerType: details?.locationType || "n/a",
    redeemType:
      (details?.offerType === "RECURRING" ? "MULTIPLE" : details?.offerType) ||
      "n/a",
    startDate: formatDate(details?.creationDate, "dd/MM/yyyy HH:mm") || "n/a",
    endDate: formatDate(details?.endDate, "dd/MM/yyyy HH:mm") || "n/a",
    offerTitle: details?.title || "n/a",
    location: details?.location || "n/a",
    description: details?.description || "n/a",
    originalPrice: details.currency + details?.originalPrice || "n/a",
    fixedDiscountedPrice:
      details.currency + details?.fixedDiscountedPrice || "n/a",
    stock: details?.stock || "n/a",
    currentStock: details?.stock - details?.redeemed || "n/a",
    redeemed: details?.redeemed || "n/a",
    status: details?.status || "n/a",
  };

  const offerMerchantDetailsToPrint: OfferMerchantDetailsToPrint = {
    partyId: details?.merchant?.partyId || "n/a",
    merchantName: details?.merchant?.name || "n/a",
  };

  useEffect(() => {
    fetchOfferDetails();
  }, []);

  const fetchOfferDetails = async () => {
    try {
      const offerRes = await fetchOfferByOfferId({
        offerId,
      });
      setDetails(offerRes);
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const deleteOffer = async () => {
    try {
      await deleteOffers({ offerId });
      await fetchOfferDetails();
      setModalOpen(false);
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const blockOffer = async () => {
    try {
      await fetchOffersBlockByOfferId({ offerId });
      await fetchOfferDetails();
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const unblockOffer = async () => {
    try {
      await fetchOffersUnblockByOfferId({ offerId });
      await fetchOfferDetails();
    } catch (err: any) {
      handleFailure(err);
    }
  };

  const margins = clsx(classes.pl24, classes.pr24, classes.mt32, classes.mb32);
  const btnMl = clsx(`${classes.buttonMargin}--left`);
  const btnMr = clsx(`${classes.buttonMargin}--right`);

  return (
    <>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>{t("modal__title__delete__offer")}</DialogTitle>
        <DialogContent>
          <div>{t("modal__content__delete__offer")}</div>
        </DialogContent>
        <div className={classes.modalButtonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={btnMr}
            onClick={() => setModalOpen(false)}
          >
            {t("btn__no__modal")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={btnMl}
            onClick={() => deleteOffer()}
          >
            {t("btn__yes__modal")}
          </Button>
        </div>
      </Dialog>
      <MainTemplate>
        <div className={classes.accountsContainer}>
          <Grid container direction={"column"} alignItems={"center"}>
            <Grid item xs={12}>
              <QuidTitle>{t("offer__details__title")}</QuidTitle>
            </Grid>
          </Grid>
        </div>
        <div className={classes.detailContainer}>
          <Grid container className={margins}>
            <Grid item xs={2}>
              <OfferImage imageId={details.imageId} />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <DetailsBlock
                label={t("offer__details__details__title")}
                toPrint={offerDetailsToPrint}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4}>
              <DetailsBlock
                label={t("offer__details__merchant__title")}
                toPrint={offerMerchantDetailsToPrint}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.buttonsContainer}>
            {process.env.REACT_APP_KEYCLOAK_REALM === "toonie" ? (
              <>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="actions actions-reject"
                    onClick={() => setModalOpen(true)}
                    disabled={details?.status === "DELETED"}
                  >
                    {t("btn__delete__offer")}
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="actions actions-reject"
                    onClick={() => {
                      history.push(`/companies/${details?.merchant?.partyId}`);
                    }}
                  >
                    {t("btn__view__merchant")}
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="actions actions-reject"
                    onClick={() => {
                      history.push(`/offers/${offerId}/customers`);
                    }}
                  >
                    {t("btn__redeems")}
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  {details?.status === "BLOCKED" && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="actions actions-reject"
                      onClick={() => unblockOffer()}
                    >
                      {t("btn__unblock__offer")}
                    </Button>
                  )}
                  {details?.status === "ACTIVE" && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="actions actions-reject"
                      onClick={() => blockOffer()}
                    >
                      {t("btn__block__offer")}
                    </Button>
                  )}
                </Grid>
              </>
            ) : (
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  className="actions actions-reject"
                  onClick={() => {
                    history.push(`/companies/${details?.merchant?.partyId}`);
                  }}
                >
                  {t("btn__view__merchant")}
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </MainTemplate>
    </>
  );
};

export default OfferDetail;
