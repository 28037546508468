import {
  Button,
  DialogContent,
  Grid,
  makeStyles,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import FullScreenModal from "components/atoms/FullScreenModal";
import { FormProvider, useForm } from "react-hook-form";
import QuidTextField from "components/atoms/QuidTextField";
import { ReconciliationDetails } from "@portit/core/entities/Accounts";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiPaper-root": {
      maxWidth: 1000,
      width: "100%",
      padding: "0 50px",
      "& .MuiDialogTitle-root .MuiTypography-root": {
        margin: "25px 0",
        fontSize: 28,
        color: theme.palette.secondary.main,
        textAlign: "center",
      },
    },
  },
  dialogContent: {
    width: "700px",
    padding: 50,
    marginLeft: "auto",
    marginRight: "auto",
  },
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    marginTop: 32,
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  form: {
    maxWidth: "100%",
  },
  flex1: {
    flex: 1,
  },
  mt56: {
    marginTop: 56,
  },
  mt16: {
    marginTop: 16,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  sectionTitle: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    marginBottom: "5px",
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 56,
  },
  modalContent: {
    overflowY: "auto",
  },
  buttonsAndSelect: {
    marginBottom: "6rem",
    display: "flex",
    gap: "1rem",
    marginLeft: "auto",
    marginRight: "auto",
    alignItems: "baseline",
  },
  "& .actions": {
    minHeight: 50,
    margin: "0 10px",

    "&-reject": {
      backgroundColor: "#FF6F0F",
    },
  },
}));

export interface CurrentReconciliation {
  id: string;
  status: string;
  priority: string;
  environment: string;
  data_source: string;
  data_provider: string;
  last_reconciled: string;
}

export interface StatusArr {
  text: string;
  code: string;
}

interface ReconciliationsDetailModalProps {
  isOpen: boolean;
  statusArr: StatusArr[];
  onCloseModal: () => void;
  reconciliation: ReconciliationDetails;
  onSubmit: (id: string, status: string) => void;
}

interface FormValues {
  status: string;
}

const defaultValues: FormValues = {
  status: "",
};

const ReconciliationDetailModal: React.FC<ReconciliationsDetailModalProps> = ({
  isOpen,
  onSubmit,
  statusArr,
  onCloseModal,
  reconciliation,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("account");

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit } = methods;

  const reconciliationToPrint: CurrentReconciliation = {
    id: reconciliation.id?.toString() || "n/a",
    status: reconciliation.status || "n/a",
    priority: reconciliation.priority || "n/a",
    environment: reconciliation.environment || "n/a",
    data_source: reconciliation.data_source || "n/a",
    data_provider: reconciliation.data_provider || "n/a",
    last_reconciled: reconciliation.last_reconciled || "n/a",
  };

  const handleOnSubmit = (values: FormValues) => {
    if (reconciliation.id && values?.status) {
      onSubmit(reconciliation.id?.toString(), values?.status);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && reconciliation) {
      methods.reset({
        status: reconciliation?.status,
      } as FormValues);
    }

    return (): void => {
      isMounted = false;
    };
  }, [reconciliation]);

  return (
    <FullScreenModal open={isOpen} onClose={() => onCloseModal()}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt56}>
            <Typography variant="h1" className={classes.title}>
              {t("account__reconciliations__detail__title")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction={"row"} className={classes.modalContent}>
          <Grid item xs={12} className={classes.mt16}>
            <DialogContent className={classes.dialogContent}>
              <DetailsBlock label="" toPrint={reconciliationToPrint} />
            </DialogContent>
          </Grid>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit(handleOnSubmit)}
              className={classes.buttonsAndSelect}
            >
              <QuidTextField
                name="status"
                rules={{
                  required: t(
                    "status__reconciliation__account__required"
                  ) as string,
                }}
                textFieldProps={{
                  select: true,
                  fullWidth: true,
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                label={t("status__reconciliation__account")}
                defaultValues={defaultValues}
              >
                <MenuItem value="">
                  {t("status__reconciliation__account")}
                </MenuItem>
                {statusArr?.map((option: StatusArr, index) => (
                  <MenuItem key={`${option}-${index}`} value={option.code}>
                    {option.text}
                  </MenuItem>
                ))}
              </QuidTextField>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={methods.handleSubmit(handleOnSubmit)}
              >
                {t("update_status__button")}
              </Button>
            </form>
          </FormProvider>
        </Grid>
      </div>
    </FullScreenModal>
  );
};

export default ReconciliationDetailModal;
