import React, { ReactNode, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";
import {
  Detail,
  KeyFact,
  Opportunity,
} from "entities/opportunities/opportunityEntity";
import { createOpportunity } from "api/opportunities";
import MainTemplate from "templates/MainTemplate";
import QuidTitle from "components/atoms/QuidTitle";
import { useTranslation } from "react-i18next";
import OpportunitiesCreateOrEdit from "components/organisms/opportunities/OpportunitiesCreateOrEdit";
import { useHistory } from "react-router";

export interface OpportunityDetailsProps {
  opportunity: Opportunity;
  children?: ReactNode;
  refetch: () => Promise<void>;
}

type FormValues = {
  name: string;
  currency: string;
  assetClass: string;
  minRequested: string;
  maxRequested: string;
  network: string;
  horizon: string;
  stage: string;
  sector: string;
  tokenPrice: string;
  valuation: string;
  shortDescription: string;
  longDescription: string;
  startDate: Date;
  endDate: Date;
  noToken: string;
  bigImageId: number;
  logoImageId: number;
  details: Detail[];
  keyFacts: KeyFact[];
  beneficiaryWalletsWalletId: string;
  beneficiaryWalletsCurrency: string;
  smartContractName: string;
  smartContractTemplate: string;
  smartContractAmount: string;
  smartContractDescription: string;
  smartContractPrecision: number;
  step: string;
  streamableWalletAddress?: string;
  streamableCurrency?: string;
  streamableSetupType?: string;
  streamableSetupApy?: string;
  streamableSetupInvestment?: string;
  streamableSetupDuration?: string;
  referralPercentage?: string;
};

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
}));

const OpportunityCreatePage: React.FC<OpportunityDetailsProps> = () => {
  const { t } = useTranslation("opportunities");
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);

  const onFormSubmit = async (values: FormValues) => {
    setLoading(true);
    try {
      await createOpportunity({
        name: values?.name,
        currency: values?.currency,
        assetClass: values?.assetClass,
        minRequested: values?.minRequested,
        maxRequested: values?.maxRequested,
        network: values?.network,
        referralPercentage: values?.referralPercentage,
        horizon: values?.horizon,
        stage: values?.stage,
        sector: values?.sector,
        tokenPrice: values?.tokenPrice,
        valuation: values?.valuation,
        shortDescription: values?.shortDescription,
        longDescription: values?.longDescription,
        noToken: parseInt(values?.noToken, 10),
        bigImageId: 0,
        logoImageId: 0,
        details: [],
        keyFacts: [],
        step: values?.step,
        smartContract: values?.smartContractName
          ? {
              name: values?.smartContractName,
              template: values?.smartContractTemplate,
              amount: values?.smartContractAmount,
              description: values?.smartContractDescription,
              precision: values?.smartContractName
                ? parseInt(values?.smartContractPrecision?.toString(), 10)
                : undefined,
              uintAmount: values?.smartContractName ? 1 : undefined,
            }
          : undefined,
        beneficiaryWallets: {
          wallets: [
            {
              currency: values?.beneficiaryWalletsCurrency,
              walletId: values?.beneficiaryWalletsWalletId,
            },
          ],
        },
        startDate: `${values?.startDate}T00:00:00.000Z`,
        endDate: `${values?.endDate}T00:00:00.000Z`,
        supportsPartial: false,
        opportunityInvestmentSetupRequest: {
          type: values.streamableSetupType,
          apy: values.streamableSetupApy,
          investment: values.streamableSetupInvestment,
          duration: values.streamableSetupDuration,
        },
        streamableWalletAddress: values.streamableWalletAddress,
        streamableCurrency: values.streamableCurrency,
      });
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("opportunity__create__success__message"),
          type: "success",
        })
      );
      history.push("/opportunities");
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const classes = useStyles();

  return (
    <MainTemplate>
      <div className={classes.root}>
        <QuidTitle>{t("opportunity__details__header_name__tab")}</QuidTitle>
        <OpportunitiesCreateOrEdit
          mode={"create"}
          isLoading={isLoading}
          onFormSubmit={onFormSubmit}
        />
      </div>
    </MainTemplate>
  );
};

export default OpportunityCreatePage;
