import { Box, Fab, Grid, makeStyles, Tab } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import MainTemplate from "templates/MainTemplate";
import TabPanel from "components/atoms/TabPanel";
import Tabs from "components/atoms/Tabs";
import { useDispatch } from "react-redux";
import promptsSlice from "store/reducers/prompts.reducer";
import DirectorInfo from "components/organisms/directors/DirectorInfo";
import DocumentList from "components/organisms/common/DocumentList";
import AddressList from "components/organisms/common/AddressList";
import { useTranslation } from "react-i18next";
import useDocHandlers from "shared/hooks/useDocHandlers";
import { handleFailure } from "resHandlers";
import QuidTitle from "components/atoms/QuidTitle";
import { ArrowBack } from "@material-ui/icons";
import {
  acceptPeopleDocument,
  createPeopleAddress,
  getPeopleAddresses,
  rejectPeopleDocument,
  updatePeople,
  updateDirectorNotes,
  uploadPeopleDocument,
} from "api/people";
import {
  downloadPeopleDocumentById,
  getDirectorDetailsByPersonId,
} from "services/people";
import {
  DirectorDetails,
  PeopleDetails,
  UpdatePeopleParams,
} from "entities/clients/People";
import { Director } from "entities/clients/PersonEntity";
import { Address } from "entities/clients/AddressEntity";
import { AddressStatus } from "entities/clients/CustomerBackofficeEntity";

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: "column",
    marginTop: 30,
    display: "flex",
    margin: "0 auto",
    width: "calc(100vw/12*9)",
  },
  flex: {
    display: "flex",
    justifyContent: "center",
  },
  flex05: {
    flex: 0.5,
  },
  flex1: {
    flex: 1,
  },
  tabsWrapper: {
    backgroundColor: "#FAFAFA",
  },
}));

const DirectorDetail: React.FC<
  RouteComponentProps<{ companyId: string; id: string }>
> = ({ match }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [, setLoading] = useState(false);
  const { t } = useTranslation("director");
  const [details, setDetails]: [
    details: DirectorDetails,
    setDetails: Function
  ] = useState({});
  const dispatch = useDispatch();
  const personId: string = match?.params?.id;
  const companyId: string = match?.params?.companyId;
  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: number
  ): void => {
    setValue(newValue);
  };
  const defaultAddressStatusParams: AddressStatus = {
    status: "CURRENT",
    use: "",
    verified: "PENDING",
  };

  const getDirectorDetails = async (
    personId: string | number
  ): Promise<void> => {
    setLoading(true);

    try {
      const details = await getDirectorDetailsByPersonId(
        companyId as string,
        personId as string
      );
      setDetails(details);
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  const {
    documentLoading,
    onDocAccept,
    onDocDownload,
    onDocReject,
    onDocUpload,
  } = useDocHandlers({
    t,
    entity: { id: personId, propName: "personId" },
    refetchEntity: getDirectorDetails,
    download: downloadPeopleDocumentById,
    accept: acceptPeopleDocument,
    reject: rejectPeopleDocument,
    upload: uploadPeopleDocument,
  });

  const refetchAddress = async () => {
    setLoading(true);
    try {
      const res = await getPeopleAddresses(personId);
      const { addresses, total: totalAddresses } = res;
      setDetails((details: PeopleDetails) => ({
        ...details,
        addresses,
        totalAddresses,
      }));
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  const updateDirector = async (params: UpdatePeopleParams, note: string) => {
    setLoading(true);
    try {
      await updatePeople(params);
      await updateDirectorNotes(companyId, personId, note);

      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("msg__update__success__director"),
          type: "success",
        })
      );
      getDirectorDetails(personId);
    } catch (err: any) {
      return handleFailure(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void getDirectorDetails(personId);
    return (): void => {};
  }, []);

  const onCreateAddress = (address: Address, use: string, partyId?: number) => {
    return createPeopleAddress({
      id: parseInt(personId, 10),
      address: {
        partyId: partyId || 0,
        address,
        status: defaultAddressStatusParams.status,
        ...(use && { use }),
      },
    });
  };

  const goBack = () => {
    history.back();
  };

  return (
    <MainTemplate>
      <div className={classes.root}>
        <Box marginBottom={2}>
          <Grid container>
            <Grid item xs={4}>
              <Fab color="primary" aria-label="back" onClick={goBack}>
                <ArrowBack />
              </Fab>
            </Grid>
            <Grid item xs={4}>
              <QuidTitle>{t("director__details__title")}</QuidTitle>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Box>
        <div className={classes.tabsWrapper}>
          <Tabs handleChange={handleChange} value={value} maxTabWidth="50%">
            <Tab label={t("tab__label__general__info")} />
            <Tab label={t("tab__label__documents")} />
            <Tab label={t("tab__label__addresses")} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <DirectorInfo
              director={details?.director as Director}
              updateDirector={updateDirector}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DocumentList
              loading={documentLoading}
              totalDocuments={details?.totalDocuments}
              documents={details?.documents}
              accept={onDocAccept}
              reject={onDocReject}
              download={onDocDownload}
              onUploadDocuments={onDocUpload}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AddressList
              addresses={details?.addresses || []}
              createAddress={onCreateAddress}
              refetch={refetchAddress}
              total={details?.totalAddresses}
              onUploadDocuments={onDocUpload}
            />
          </TabPanel>
        </div>
      </div>
    </MainTemplate>
  );
};

export default DirectorDetail;
