import { ContractsParams, ContractsResponse } from "../entities/Products";
import { apiClient } from "../utils/api/apiClient";
import { Customers } from "../entities/Parties";

export async function getContractsByProductId(
  params: ContractsParams
): Promise<ContractsResponse> {
  try {
    const res = await apiClient.get<ContractsResponse>(
      `/products/v1/productsManagement/${params.productId}/parties`,
      "product",
      {
        page: params.page,
        size: params.size,
        orderType: "desc",
      }
    );

    const resCustomers = await apiClient.get<Customers>(
      `/parties/v1/customers/`,
      "parties",
      {
        pageSize: 10000000,
      }
    );
    return {
      ...res,
      contracts: res.contracts.map((contract) => {
        return {
          ...contract,
          customer: resCustomers.customerList.find((customer) =>
            customer.company
              ? customer.company.id === contract.ownerId
              : customer.id === contract.ownerId
          ),
        };
      }),
    };
  } catch (err: any) {
    throw err;
  }
}
