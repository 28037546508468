import { Grid, Typography, MenuItem, makeStyles } from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import ApproveRejectButtons from "./ApproveRejectButtons";
import QuidTextField from "./QuidTextField";
import QuidUploadField from "./QuidUploadField";
import { useTranslation } from "react-i18next";
import { createComment } from "api/tasks";
import {
  VerificationCommentsRequest,
  VerificationRequest,
} from "entities/tasks/Common";
import { Task } from "entities/tasks/TaskEntity";
import { Failure } from "features/core/Failure";
export interface ApproveRejectKycIndividualBoxProps {
  accept: (values: VerificationRequest) => Promise<unknown>;
  reject: (values: VerificationRequest) => Promise<unknown>;
  task?: any;
  taskKey: Task["taskDefinitionKey"];
  screeningNotRequired?: boolean;
  documentNotRequired?: boolean;
  identityNotRequired?: boolean;
}

interface EkycSelectProps {
  name: string;
}

const EkycSelect: React.FC<EkycSelectProps> = ({ name }) => {
  return (
    <QuidTextField
      name={name}
      rules={{ required: "This field is required" }}
      label="Choose"
      defaultValues={defaultValues}
      textFieldProps={{ select: true, fullWidth: true }}
    >
      <MenuItem value="">Choose</MenuItem>
      <MenuItem value="verified">Verified</MenuItem>
      <MenuItem value="not_verified">Not Verified</MenuItem>
      <MenuItem value="ekyc_not_applicable">E-KYC not applicable</MenuItem>
      <MenuItem value="manually_verified">Manually verified</MenuItem>
      <MenuItem value="additional_info_requested">
        Additional info/documents requested
      </MenuItem>
    </QuidTextField>
  );
};

const EkycSelect3: React.FC<EkycSelectProps> = ({ name }) => {
  return (
    <QuidTextField
      name={name}
      rules={{ required: "This field is required" }}
      label="Choose"
      defaultValues={defaultValues}
      textFieldProps={{ select: true, fullWidth: true }}
    >
      <MenuItem value="">Choose</MenuItem>
      <MenuItem value="verified">Verified</MenuItem>
      <MenuItem value="not_verified">Not Verified</MenuItem>
      <MenuItem value="manually_verified">Manually verified</MenuItem>
      <MenuItem value="additional_info_requested">
        Additional info/documents requested
      </MenuItem>
    </QuidTextField>
  );
};

const EkycSelect2: React.FC<EkycSelectProps> = ({ name }) => {
  return (
    <QuidTextField
      name={name}
      rules={{ required: "This field is required" }}
      label="Choose"
      defaultValues={defaultValues}
      textFieldProps={{ select: true, fullWidth: true }}
    >
      <MenuItem value="">Choose</MenuItem>
      <MenuItem value="no_match">No match</MenuItem>
      <MenuItem value="false_positive_match">False positive match</MenuItem>
      <MenuItem value="positive_match">Positive match</MenuItem>
      <MenuItem value="screening_not_applicable">
        Screening not applicable
      </MenuItem>
    </QuidTextField>
  );
};

interface FormValues {
  comment1: VerificationCommentsRequest;
  comment2: VerificationCommentsRequest;
  comment3: VerificationCommentsRequest;
}

const defaultValues: FormValues = {
  comment1: {
    comment: "",
  },
  comment2: {
    comment: "",
  },
  comment3: {
    comment: "",
  },
};

const useStyles = makeStyles((theme) => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  sectionTitle: {
    marginTop: 16,
    color: theme.palette.primary.main,
    fontSize: 18,
    marginBottom: 16,
  },
  textarea: {
    marginTop: 18,
    marginBottom: 22,
  },
  mt24: {
    marginTop: 24,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    padding: 50,
    backgroundColor: "#FAFAFA",
    "& .MuiTextField-root": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .box": {
      display: "flex",
      flexWrap: "wrap",
      padding: "50px 100px",
      backgroundColor: "#FFF",
      borderRadius: 60,

      "& .MuiFormControlLabel-root, & .MuiTextField-root": {
        width: "100%",
      },

      "& .reject": {
        textAlign: "center",
        marginBottom: 20,
        color: "#FF6F0F",
      },

      "& .actions": {
        minHeight: 50,
        margin: "0 10px",

        "&-reject": {
          backgroundColor: "#FF6F0F",
        },
      },
    },
  },
}));

const ApproveRejectKycIndividualBox: React.FC<ApproveRejectKycIndividualBoxProps> = ({
  accept,
  reject,
  task,
  taskKey,
  screeningNotRequired,
  documentNotRequired,
  identityNotRequired,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation("kyc");
  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const [docs, setDocs] = useState<{
    identity?: File;
    document?: File;
    screening?: File;
  }>({});

  const sendComments = async (values: FormValues) => {
    try {
      if (docs?.identity) {
        await createComment({
          taskKey,
          taskId: task?.task?.id,
          request: {
            comment: `${t("form_label.identity_result")}: ${
              values.comment1.comment
            }`,
            files: docs.identity,
          },
        });
      }
      if (docs?.document) {
        await createComment({
          taskKey,
          taskId: task?.task?.id,
          request: {
            comment: `${t("form_label.document_result")}: ${
              values.comment3.comment
            }`,
            files: docs.document,
          },
        });
      }
      if (docs?.screening) {
        await createComment({
          taskKey,
          taskId: task?.task?.id,
          request: {
            comment: `${t("form_label.screening_result")}: ${
              values.comment2.comment
            }`,
            files: docs.screening,
          },
        });
      }
    } catch (e) {
      throw e;
    }
  };

  const onReject = async (values: FormValues) => {
    try {
      await sendComments(values);
      await reject({
        checks: {
          ALL_IS_OK: false,
        },
        result: {
          reason: "rejected",
          description: JSON.stringify({
            checks: {
              KYC_IDENTITY_RESULT: values?.comment1?.comment,
              KYC_DOCUMENT_RESULT: values?.comment3?.comment,
              KYC_SCREENING_RESULT: values?.comment2?.comment,
            },
          }),
        },
      });
      handleSuccessfulMessage("Action received");
      history.replace("/");
    } catch (e) {
      handleFailure((e as unknown) as Failure);
    }
  };

  const onAccept = async (values: FormValues) => {
    try {
      await sendComments(values);
      await accept({
        checks: {
          ALL_IS_OK: true,
        },
        result: {
          reason: "approved",
          description: JSON.stringify({
            checks: {
              KYC_IDENTITY_RESULT: values?.comment1?.comment,
              KYC_DOCUMENT_RESULT: values?.comment3?.comment,
              KYC_SCREENING_RESULT: values?.comment2?.comment,
            },
          }),
        },
      });
      handleSuccessfulMessage("Action received");
      history.replace("/");
    } catch (e) {
      handleFailure((e as unknown) as Failure);
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Grid item xs={12} className={classes.container}>
          <Grid item xs={10} className="box">
            {!identityNotRequired && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    {t("form_label.identity_result")}
                  </Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <QuidUploadField
                      inputId="identity-doc"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setDocs((docs) => ({
                          ...docs,
                          identity: e?.target?.files?.[0],
                        }))
                      }
                      value={docs.identity?.name}
                      label={!docs.identity?.name && "Upload document"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EkycSelect name="comment1.comment" />
                  </Grid>
                </Grid>
              </>
            )}
            {!documentNotRequired && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    {t("form_label.document_result")}
                  </Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <QuidUploadField
                      inputId="document-doc"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setDocs((docs) => ({
                          ...docs,
                          document: e?.target?.files?.[0],
                        }))
                      }
                      value={docs.document?.name}
                      label={!docs.document?.name && "Upload document"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EkycSelect3 name="comment3.comment" />
                  </Grid>
                </Grid>
              </>
            )}
            {!screeningNotRequired && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.sectionTitle}>
                    {t("form_label.screening_result")}
                  </Typography>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <QuidUploadField
                      inputId="screening-doc"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setDocs((docs) => ({
                          ...docs,
                          screening: e?.target?.files?.[0],
                        }))
                      }
                      value={docs.screening?.name}
                      label={!docs.screening?.name && "Upload document"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <EkycSelect2 name="comment2.comment" />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12} className={classes.mt24}>
              <ApproveRejectButtons
                onAccept={methods.handleSubmit(onAccept)}
                onReject={methods.handleSubmit(onReject)}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ApproveRejectKycIndividualBox;
