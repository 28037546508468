import { Dataset } from "entities/clients/CustomerBackofficeEntity";
import { Pagination } from "./Pagination";
export const PaymentType = {
  REGULAR: "regular",
  PRIORITY: "priority",
};

export const BalanceStatus: Dataset<string> = {
  pending: "Pending",
  enabled: "Enabled",
  disabled: "Disabled",
  enabled_no_trading: "Enabled No Trading",
};

export interface DownloadStatementsParams {
  balanceId: number;
  month?: string;
  year?: string;
  startdate?: string;
  enddate?: string;
  fileName?: string;
}

export interface FetchBalancesParams {
  keyword?: string;
  type?: string;
  currency?: string;
  status?: string;
  page?: number;
  size?: number;
  order?: string;
  order_type?: "asc" | "desc";
  customerType?: string;
  partyId?: number | string;
}

export interface FetchBalanceByAccountIdParams {
  account_id: number;
  page?: number;
  size?: number;
  order?: string;
  orderType?: string;
}

export interface FetchBalanceParams {
  balance_id: number;
}

export interface FetchBalanceDetailParams {
  balance_id: number;
}

export interface BalanceDetailResponse {
  account_id: number;
  status:
    | "pending"
    | "pending_customer"
    | "pending_provider"
    | "enabled"
    | "disabled"
    | "enabled_no_trading"
    | "rejected";
  account_name: string;
  account_holder: string;
  quid_reference: string;
  account_type:
    | "revenue"
    | "house"
    | "customer"
    | "fx_fee"
    | "transaction_fee"
    | "monthly_fee";
  legal_entity: string;
  street: string;
  city: string;
  postal_code: string;
  country: string;
  identification_type: string;
  identification_value: string;
  state_or_province: string;
  funding_info: FundingInfo[];
}

export interface RawBalance {
  balance_id: number;
  account_id: number;
  currency: string;
  account_holder: string;
  type: string;
  status: string;
  current_amount: number;
  available_amount: number;
  funding_info: FundingInfo[];
}

export interface Balance extends RawBalance {}

export interface FundingInfo {
  account_holder_name: string;
  beneficicary_address: string;
  account_number: string;
  account_number_type: string;
  beneficiary_country: string;
  bank_address: string;
  bank_country: string;
  bic_swift: string;
  iban: string;
  bank_name: string;
  currency: string;
  payment_type: string;
  routing_code: string;
  routing_code_type: string;
}

export interface BalancesListParams extends RawBalance {
  account_name: string;
  type: string;
  currency: string;
  account_type: string;
  customerType: string;
}

export interface BalanceResponse extends RawBalance {
  creation_date: string; // TODO: this should be added by BE, so WIP for now
  iban: string; // TODO: this should be added by BE, so WIP for now
  account_name: string;
  account_type: string;
  first_name: string;
  last_name: string;
  legal_entity: string;
  street: string;
  city: string;
  postal_code: string;
  country: string;
  identification_type: string;
  identification_value: string;
  state_or_province: string;
  wallet_id: string;
}

export interface BalancesResponse {
  pagination: Pagination;
  balances: RawBalance[];
}

export interface DownloadBalanceParams {
  fileName?: string;
  type?: string;
  account_id?: number;
}
