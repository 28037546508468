import {
  makeStyles,
  Snackbar as SnackbarComponent,
  SnackbarContent,
} from "@material-ui/core";
import promptsSlice from "store/reducers/prompts.reducer";
import { getSnackbarState } from "store/reducers/app.reducer";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import React from "react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CopyIcon from "@material-ui/icons/FileCopy";
const SIX_SECONDS = 6 * 1000;
const useStyles = makeStyles(() => ({
  error: {
    backgroundColor: "#f44336",
  },
  warning: {
    backgroundColor: "#FFAB00",
  },
  success: {
    backgroundColor: "#4caf50",
  },
  btn: {
    width: 28,
    "&.MuiButton-root": {
      width: 28,
    },
  },
  btnColor: {
    color: "#ffffff",
  },
}));

const Snackbar: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const snackbarState = useSelector(getSnackbarState);
  const snackbarColor = clsx(`${classes[snackbarState.type]}`);

  const handleClose = () => dispatch(promptsSlice.actions.closeSnackbar());
  return (
    <SnackbarComponent
      open={snackbarState.open}
      autoHideDuration={SIX_SECONDS}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <SnackbarContent
        message={snackbarState.message}
        classes={{
          root: snackbarColor,
        }}
        action={
          <div style={{ flexDirection: "row", alignItems: "center" }}>
            <IconButton
              title="Copy error url"
              size="small"
              aria-label="copy url"
              classes={{ root: classes.btnColor }}
              onClick={() => {
                if (snackbarState.url) {
                  navigator.clipboard.writeText(
                    `URL: ${snackbarState.url}
${snackbarState.data && `Data: ${snackbarState.data}`}`
                  );
                }
              }}
            >
              <CopyIcon fontSize="small" />
            </IconButton>
            <IconButton
              size="small"
              aria-label="close"
              classes={{ root: classes.btnColor }}
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        }
      ></SnackbarContent>
    </SnackbarComponent>
  );
};

export default Snackbar;
