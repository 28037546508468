import { OfferDetailsParams } from "../entities/Offers";
import { apiClient } from "../utils/api/apiClient";

export async function fetchOffersBlockByOfferId(
  params: OfferDetailsParams
): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/offers/v1/offers/${params.offerId}/block`,
      "company"
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}

export async function fetchOffersUnblockByOfferId(
  params: OfferDetailsParams
): Promise<void> {
  try {
    const res = await apiClient.post<void>(
      `/offers/v1/offers/${params.offerId}/unblock`,
      "company"
    );
    return res;
  } catch (err: any) {
    throw err;
  }
}
