import DineroFactory from "dinero.js";
import Dinero from "dinero.js";
import { toCents } from "utils";

export const MoneyFormatter = (
  amount: number,
  currency:
    | DineroFactory.Currency
    | "USDT"
    | "AIVAR"
    | "BTC"
    | "STREAM"
    | "USDC"
    | "ETH"
    | "strUSDT"
    | "strETH"
    | "strBTC"
    | "strUSDC"
    | "DAI"
    | "strDAI"
    | "BUSD"
    | "strBUSD"
    | "esGBP"
    | "esUSD"
) => {
  try {
    if (amount === null || !currency) return "n/a";
    if (
      currency === "USDT" ||
      currency === "AIVAR" ||
      currency === "BTC" ||
      currency === "STREAM" ||
      currency === "ETH" ||
      currency === "USDC" ||
      currency === "strUSDT" ||
      currency === "strETH" ||
      currency === "strBTC" ||
      currency === "strUSDC" ||
      currency === "DAI" ||
      currency === "strDAI" ||
      currency === "BUSD" ||
      currency === "strBUSD" ||
      currency === "esGBP" ||
      currency === "esUSD"
    )
      return `${currency} ${amount}`;
    return Dinero({ amount: toCents(amount), currency }).toFormat("$0,0.00");
  } catch (e) {
    if (typeof amount === "string") {
      return `${currency?.toString()} ${amount}`;
    } else {
      return `${currency?.toString()} ${amount?.toPrecision(3).toString()}`;
    }
  }
};
