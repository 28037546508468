import { Grid, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MainTemplate from "templates/MainTemplate";
import { Link, RouteComponentProps } from "react-router-dom";
import clsx from "clsx";
import FullScreenModal from "components/atoms/FullScreenModal";
import CreditModal from "../components/organisms/accounts/CreditModal";
import DebitModal from "../components/organisms/accounts/DebitModal";
import QuidTitle from "components/atoms/QuidTitle";
import { handleFailure } from "resHandlers";
import { firstCapitalLetter } from "utils";
import AccountStatusBadge, {
  AccountBadgeStatuses,
} from "components/organisms/accounts/AccountStatusBadge";
import AccountBalances from "components/organisms/accounts/AccountBalances";
import { AccountResponse } from "entities/accounts/Account";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import { fetchAccountByAccountId } from "api/accounts";

const useStyles = makeStyles((theme) => ({
  accountsContainer: {
    width: "calc(100vw/12*9)",
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
  },
  status: {
    display: "flex",
    fontSize: 14,
    flexDirection: "row",
    paddingRight: 24,
  },
  infoContainer: {
    width: "100%",
    height: 60,
    display: "flex",
    fontSize: 18,
    flexDirection: "row",
    background: "#F3F3F3",
    alignItems: "center",
    color: "#37383C",
    justifyContent: "space-between",
    "& a": {
      color: theme.palette.secondary.main,
      textDecoration: "underline",
      paddingLeft: 24,
    },
    "& span": {
      paddingLeft: 24,
    },
  },
  detailContainer: {
    background: "#FAFAFA",
    width: "100%",
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  flex1: {
    flex: 1,
  },
  flexContainer: {
    display: "flex",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexColumn: {
    flexDirection: "column",
  },
  mt16: {
    marginTop: 16,
  },
  pl24: {
    paddingLeft: 24,
  },
  pr8: {
    paddingRight: 8,
  },
  pl8: {
    paddingLeft: 8,
  },
  pr24: {
    paddingRight: 24,
  },
  mt32: {
    marginTop: 32,
  },
  mb32: {
    marginBottom: 32,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  red: {
    background: "#FB4C4C",
  },
  green: {
    background: "#94E60F",
  },
  fullHeight: {
    height: "100%",
  },
  actionBtn: {
    height: 52,
    "&.MuiButton-root": {
      width: "90%",
    },
  },
  taskLink: {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
}));

type FullScreenState = {
  open: boolean;
  type: "credit" | "debit" | "";
};

export interface AccountInfo {
  account_name: string;
  account_holder: string;
  quid_reference: string;
  account_type: string;
  street: string;
  city: string;
  postal_code: string;
  country: string;
  state_or_province: string;
}

export interface AdditionalInfo {
  legal_entity: string;
  identification_type: string;
  identification_value: string;
}

const AccountCustomerDetail: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("account");
  const accountId = parseInt(match.params.id, 10);
  const [details, setDetails] = useState({} as AccountResponse);

  const [fullScreenModal, setFullScreenModal] = useState({
    open: false,
    type: "",
  } as FullScreenState);

  const accountDetails: AccountInfo = {
    account_name: details?.account_name || "n/a",
    account_holder: details?.account_holder || "n/a",
    quid_reference: details?.quid_reference || "n/a",
    account_type: details?.account_type || "n/a",
    street: details?.street || "n/a",
    city: details?.city || "n/a",
    postal_code: details?.postal_code || "n/a",
    country: details?.country || "n/a",
    state_or_province: details?.state_or_province || "n/a",
  };

  const additionalInfo: AdditionalInfo = {
    identification_type: details?.identification_type || "n/a",
    identification_value: details?.identification_value || "n/a",
    legal_entity: details?.legal_entity || "n/a",
  };

  useEffect(() => {
    const fetchBalanceDetails = async () => {
      try {
        const accountRes = await fetchAccountByAccountId({
          accountId: accountId.toString(),
        });
        setDetails(accountRes);
      } catch (err: any) {
        handleFailure(err);
      }
    };
    fetchBalanceDetails();
  }, []);

  const margins = clsx(classes.pl24, classes.pr24, classes.mt32, classes.mb32);

  return (
    <MainTemplate>
      <FullScreenModal
        open={fullScreenModal.open}
        onClose={() => setFullScreenModal({ open: false, type: "" })}
      >
        {fullScreenModal.type === "credit" && <CreditModal />}
        {fullScreenModal.type === "debit" && <DebitModal />}
      </FullScreenModal>
      <div className={classes.accountsContainer}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12}>
            <QuidTitle>{t("account__details__title")}</QuidTitle>
          </Grid>
        </Grid>
        <div className={classes.infoContainer}>
          <>
            <Link to={`/customers/${details?.owner_id}`}>
              {details?.account_holder}
            </Link>
          </>
          <div className={classes.status}>
            <AccountStatusBadge
              status={details?.status as AccountBadgeStatuses}
            />
            <span>{firstCapitalLetter(details?.status)}</span>
          </div>
        </div>
        <div className={classes.detailContainer}>
          <Grid
            container
            direction="row"
            className={margins}
            justify={"center"}
          >
            <Grid item xs={4}>
              <DetailsBlock
                label="account__details__menu__title"
                toPrint={accountDetails}
              />
            </Grid>
            <Grid item xs={4}>
              <DetailsBlock
                label="account__additional_details"
                toPrint={additionalInfo}
              />
            </Grid>
          </Grid>
        </div>
        <AccountBalances accountId={accountId} />
      </div>
    </MainTemplate>
  );
};

export default AccountCustomerDetail;
