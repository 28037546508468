import {
  Button,
  makeStyles,
  MenuItem,
  Grid,
  Typography,
} from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  getPartiesAddressTypes,
  getPartiesAddressUses,
  getPartiesDocumentTypes,
  getPartiesDocumentSubTypes,
} from "store/reducers/app.reducer";
import { getPartiesCountries } from "store/reducers/app.reducer";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import QuidUploadField from "../../atoms/QuidUploadField";
import FullScreenModal from "components/atoms/FullScreenModal";
import {
  Address,
  AddressType,
  AddressUse,
} from "entities/clients/AddressEntity";
import { Country } from "entities/clients/CountryEntity";
import { DocumentSubType, DocumentType } from "entities/clients/DocumentEntity";
import { Profile } from "entities/clients/ProfileEntity";

export interface CreateAddressModalProps {
  open: boolean;
  isCustomer: boolean | undefined;
  onCloseModal: (open: boolean) => void;
  customerProfiles?: Profile[];
  createAddress: (
    address: Address,
    uses: string,
    partyId?: number
  ) => Promise<void>;
  refetch: () => void;
  onUpload: CallableFunction;
}

const useStyles = makeStyles((theme) => ({
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    marginTop: 32,
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  form: {
    maxWidth: "100%",
  },
  flex1: {
    flex: 1,
  },
  mt56: {
    marginTop: 56,
  },
  mt16: {
    marginTop: 16,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  sectionTitle: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    marginBottom: "5px",
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 56,
  },
  "& .actions": {
    minHeight: 50,
    margin: "0 10px",

    "&-reject": {
      backgroundColor: "#FF6F0F",
    },
  },
}));

interface FormValues {
  addressType: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postalCode: string;
  state: string;
  country: string;
  addressUse: string;
  partyId?: number | string;
  docType: string;
  docSubType: string;
  docCountry?: string;
}

const CreateAddressModal: React.FC<CreateAddressModalProps> = ({
  open,
  createAddress,
  isCustomer,
  customerProfiles,
  refetch,
  onUpload,
  onCloseModal,
}) => {
  const { t } = useTranslation("address");
  const classes = useStyles();
  const [document, setDocument] = useState<File | "missing">();
  const documentIsMissing = document === "missing";
  const [addressUses, setAddressUses] = useState(Array<AddressUse>());

  const docValue = !documentIsMissing
    ? (document as File)?.name?.split(/(\\|\/)/g)?.pop()
    : "";

  const dispatch = useDispatch();
  const countries = useSelector(getPartiesCountries);
  const addressTypes = useSelector(getPartiesAddressTypes);
  const customerAddressUses = useSelector(getPartiesAddressUses);

  const partyAddressUses: Array<AddressUse> = [
    { code: "REGISTERED", text: "Registered " },
    { code: "TRADING", text: "Trading" },
    { code: "BILLING", text: "billing" },
    { code: "COMMUNICATION", text: "Communication" },
    { code: "TAX_RESIDENCY", text: "Tax residency" },
    { code: "PREMISE", text: "Tax premises" },
  ];

  const docTypes: Array<DocumentType> = useSelector(getPartiesDocumentTypes);
  const docSubTypes: Array<DocumentSubType> = useSelector(
    getPartiesDocumentSubTypes
  );

  const defaultValues: FormValues = {
    addressType: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    postalCode: "",
    partyId: "",
    country: "",
    state: "",
    addressUse: "",
    docCountry: "",
    docType: "",
    docSubType: "",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });

  const { handleSubmit, reset } = methods;

  const onFormSubmit = async (values: FormValues) => {
    try {
      if (values.docType !== "" && values.docSubType !== "") {
        if (!document) {
          setDocument("missing");
          return;
        }
        await onUpload({
          document,
          type: values.docType,
          subType: values.docSubType,
          country: values.docCountry,
        });
      }
      await createAddress(
        {
          addressLine1: values.addressLine1,
          addressLine2: values.addressLine2 || "",
          type: values.addressType,
          state: values.state,
          country: values.country,
          city: values.city,
          postalCode: values.postalCode,
        },
        values?.addressUse,
        values?.partyId as number
      );
      refetch();
      reset(defaultValues);
      setDocument(undefined);
      dispatch(
        promptsSlice.actions.openSnackbar({
          message: t("address__modal__successful__message"),
          type: "success",
        })
      );
      onCloseModal(false);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    const getDocumentUses = () => {
      try {
        if (isCustomer) {
          setAddressUses(customerAddressUses);
        } else setAddressUses(partyAddressUses);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    };

    void getDocumentUses();
  }, []);

  return (
    <FullScreenModal open={open} onClose={() => onCloseModal(false)}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt56}>
            <Typography variant="h1" className={classes.title}>
              {t("address__modal__title")}
            </Typography>
          </Grid>
        </Grid>
        <FormProvider {...methods}>
          <form className={classes.form} onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={8}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  {t("document__modal__address_information__title")}
                </Typography>
                <Grid container direction={"row"} spacing={1}>
                  <Grid item direction={"column"} xs={6}>
                    <Grid item>
                      {customerProfiles && (
                        <QuidTextField
                          name="partyId"
                          rules={{}}
                          textFieldProps={{
                            select: true,
                            fullWidth: true,
                          }}
                          label={t("address__modal__party_id")}
                          defaultValues={defaultValues}
                        >
                          {customerProfiles.map((profile: Profile) => (
                            <MenuItem
                              key={`party-id-${profile.partyId}`}
                              value={profile.partyId}
                            >
                              {profile.partyId} - {profile.name}
                            </MenuItem>
                          ))}
                        </QuidTextField>
                      )}
                    </Grid>
                    <Grid item>
                      <QuidTextField
                        name="addressType"
                        rules={{
                          required: t(
                            "address__modal__type__required"
                          ) as string,
                        }}
                        textFieldProps={{
                          select: true,
                          fullWidth: true,
                        }}
                        label={t("address__modal__type")}
                        defaultValues={defaultValues}
                      >
                        {addressTypes.map((addrType: AddressType) => (
                          <MenuItem key={addrType.code} value={addrType.code}>
                            {addrType.text}
                          </MenuItem>
                        ))}
                      </QuidTextField>
                    </Grid>
                    <Grid item>
                      <QuidTextField
                        name="addressLine1"
                        label={t("address__modal__addressLine1")}
                        rules={{
                          required: t(
                            "address__modal__addressLine1__required"
                          ) as string,
                        }}
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        defaultValues={defaultValues}
                      />
                    </Grid>
                    <Grid item>
                      <QuidTextField
                        name="addressLine2"
                        label={t("address__modal__addressLine2")}
                        rules={{}}
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        defaultValues={defaultValues}
                      />
                    </Grid>
                    <Grid item>
                      <QuidTextField
                        name="city"
                        label={t("address__modal__city")}
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        rules={{
                          required: t(
                            "address__modal__city__required"
                          ) as string,
                        }}
                        defaultValues={defaultValues}
                      />
                    </Grid>
                  </Grid>
                  <Grid item direction={"column"} xs={6}>
                    <Grid item>
                      <QuidTextField
                        name="postalCode"
                        label={t("address__modal_postal_code")}
                        rules={{
                          required: t(
                            "address__modal_postal_code__required"
                          ) as string,
                        }}
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        defaultValues={defaultValues}
                      />
                    </Grid>
                    <Grid item>
                      <QuidTextField
                        name="state"
                        label={t("address__modal__state")}
                        textFieldProps={{
                          fullWidth: true,
                        }}
                        rules={{
                          required: t(
                            "address__modal__state__required"
                          ) as string,
                        }}
                        defaultValues={defaultValues}
                      />
                    </Grid>
                    <Grid item>
                      <QuidTextField
                        name="country"
                        rules={{
                          required: t(
                            "address__modal__country__required"
                          ) as string,
                        }}
                        textFieldProps={{
                          select: true,
                          fullWidth: true,
                        }}
                        label={t("address__modal__country")}
                        defaultValues={defaultValues}
                      >
                        {countries.map((country: Country) => (
                          <MenuItem key={country.code} value={country.code}>
                            {country.text}
                          </MenuItem>
                        ))}
                      </QuidTextField>
                    </Grid>
                    <Grid item>
                      <QuidTextField
                        name="addressUse"
                        rules={{
                          required: t(
                            "address__modal__use__required"
                          ) as string,
                        }}
                        textFieldProps={{
                          select: true,
                          fullWidth: true,
                        }}
                        label={t("address__modal__use")}
                        defaultValues={defaultValues}
                      >
                        {addressUses.map((addressUse: AddressUse) => (
                          <MenuItem
                            key={addressUse.code}
                            value={addressUse.code}
                          >
                            {addressUse.text}
                          </MenuItem>
                        ))}
                      </QuidTextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" className={classes.sectionTitle}>
                  {t("document__modal__document_information__title")}
                </Typography>
                <Grid container direction={"column"}>
                  <Grid item>
                    <QuidTextField
                      name="docType"
                      rules={{}}
                      textFieldProps={{
                        select: true,
                        fullWidth: true,
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                      label={t("document__upload__type")}
                      defaultValues={defaultValues}
                    >
                      <MenuItem value="">
                        {t("document__upload__type")}
                      </MenuItem>
                      {docTypes.map((docType: DocumentType) => (
                        <MenuItem key={docType.code} value={docType.code}>
                          {docType.text}
                        </MenuItem>
                      ))}
                    </QuidTextField>
                  </Grid>
                  <Grid item>
                    <QuidTextField
                      name="docSubType"
                      rules={{}}
                      textFieldProps={{
                        select: true,
                        fullWidth: true,
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                      label={t("document__upload__subtype")}
                      defaultValues={defaultValues}
                    >
                      <MenuItem value="">
                        {t("document__upload__subtype")}
                      </MenuItem>
                      {docSubTypes.map((docSubType: DocumentSubType) => (
                        <MenuItem key={docSubType.code} value={docSubType.code}>
                          {docSubType.text}
                        </MenuItem>
                      ))}
                    </QuidTextField>
                  </Grid>
                  <Grid item>
                    <QuidTextField
                      name="docCountry"
                      rules={{}}
                      textFieldProps={{
                        select: true,
                        fullWidth: true,
                        InputLabelProps: {
                          shrink: true,
                        },
                      }}
                      label={t("document__upload__country")}
                      defaultValues={defaultValues}
                    >
                      <MenuItem value="">
                        {t("document__upload__country")}
                      </MenuItem>
                      {countries.map((country: Country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.text}
                        </MenuItem>
                      ))}
                    </QuidTextField>
                  </Grid>
                  <Grid item>
                    <QuidUploadField
                      inputId="doc-upload"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setDocument(e?.target?.files?.[0])
                      }
                      value={documentIsMissing ? "" : docValue}
                      label={t("document__upload__label")}
                      error={documentIsMissing}
                      helperText={
                        documentIsMissing ? "Document required" : null
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.buttonsWrapper}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="actions actions-approve"
                >
                  {t("address__modal__action__save__address")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
    </FullScreenModal>
  );
};

export default CreateAddressModal;
