import { downloadOffersDocument } from "api/offers";
import { format } from "date-fns";
import { FilePayload } from "entities/clients/CustomerBackofficeEntity";
import { DownloadOfferParams } from "entities/offer/OfferEntity";
import fileDownload from "js-file-download";

export async function downloaOffersCsv(
  params: DownloadOfferParams
): Promise<FilePayload> {
  try {
    const res = await downloadOffersDocument(params);
    const dateTime = format(new Date(), "dd-MM-yyyy_HH:mm");
    fileDownload(
      res.data.buffer,
      `offers-${dateTime}.csv`,
      res.data.contentType
    );
    return res;
  } catch (e: unknown) {
    throw e;
  }
}
