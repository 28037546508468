import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Button,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import QuidTextField from "components/atoms/QuidTextField";
import QuidTitle from "components/atoms/QuidTitle";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAccountStatus, getAccountTypes } from "store/reducers/app.reducer";
import MainTemplate from "templates/MainTemplate";
import SearchIcon from "components/atoms/icons/SearchIcon";
import { AccountResponse } from "../entities/accounts/Account";
import { handleFailure } from "resHandlers";
import { ServerFailure } from "features/core/Failure";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import promptsSlice from "store/reducers/prompts.reducer";
import { NetworkFailure } from "features/core/NetworkFailure";
import { useHistory } from "react-router";
import {
  getAccountRate,
  createTemporaryRate,
  fetchUserAccount,
} from "api/accounts";
import { AccountDataset } from "entities/accounts/AccountDataset";
import { Balance } from "entities/accounts/Balance";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import DineroFactory from "dinero.js";
import Loader from "components/atoms/icons/Loader";
import { TABLE_PAGE_SIZE } from "shared/constants";

const useStyles = makeStyles((theme) => ({
  container: { backgroundColor: "#FAFAFA" },
  containerInner: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  column4: { width: "calc(100% / 3 - 30px)", padding: "0px 15px" },
  amountdetails: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  containerInnerButtons: {
    justifyContent: "center",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    paddingTop: 60,
  },
  checkText: {
    textAlign: "center",
    paddingTop: 13,
    fontFamily: "Nunito",
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: 18,
  },
  accountDetailsWrapper: {
    background: "#fff",
    padding: 15,
    border: "1px solid #ddd",
    borderRadius: 4,
    marginTop: 15,
    cursor: "pointer",
  },
  textField: {
    background: "#ffffff",
    marginBottom: 15,
  },
}));

interface FormValues {
  currencyFrom: string;
  currencyTo: string;
  operation: string;
  amount: number;
  reason: string;
  account_id: number;
}

const PaymentsInternalConversion: React.FC = () => {
  const { t } = useTranslation("payments");

  const [accounts, setAccounts] = useState<AccountResponse[]>([]);
  const [account, setAccount] = useState<AccountResponse>();
  const [search, setSearch] = useState("");
  const [toAmount, setToAmount] = useState("0.00");
  const [fromAmount, setFromAmount] = useState("0.00");
  const [exchageRate, setExchangeRate] = useState("0.00");
  const [isFromSelected, setIsFromSelected] = useState(false);
  const [currencyFrom, setCurrencyFrom] = useState("");
  const [currencyTo, setCurrencyTo] = useState("");
  const [balance, setBalance] = useState({} as Balance | undefined);

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const defaultValues = {
    account_type: "",
    account_status: "enabled",
    amount: fromAmount,
    currencyto: "",
    currencyFrom: "",
  };

  const accountDetails = {
    account_name: account?.account_name || "n/a",
    account_holder: account?.account_holder || "n/a",
    quid_reference: account?.quid_reference || "n/a",
    account_type: account?.account_type || "n/a",
    street: account?.street || "n/a",
    city: account?.city || "n/a",
    postal_code: account?.postal_code || "n/a",
    country: account?.country || "n/a",
    state_or_province: account?.state_or_province || "n/a",
    account_status: account?.status || "n/a",
  };

  const transactionDetails = {
    current_amount:
      `${MoneyFormatter(
        balance?.current_amount || 0,
        currencyFrom as DineroFactory.Currency
      )}` || "n/a",
    available_amount:
      `${MoneyFormatter(
        balance?.available_amount || 0,
        currencyFrom as DineroFactory.Currency
      )}` || "n/a",
  };

  const methods = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const { handleSubmit, watch, reset } = methods;

  const accountTypes = useSelector(getAccountTypes);
  const accountStatuses = useSelector(getAccountStatus);
  const accountType = watch("account_type");
  const accountStatus = watch("account_status");

  const onResetForm = () => {
    reset(defaultValues);
    setAccount(undefined);
    setBalance(undefined);
    setCurrencyFrom("");
    setCurrencyTo("");
    setSearch("");
    setAccounts([]);
    setIsFromSelected(false);
  };

  useEffect(() => {
    if (currencyFrom) {
      setBalance(
        account?.balances?.filter(
          (balance: Balance) => balance?.currency === currencyFrom
        )[0]
      );
    }
  }, [currencyFrom]);

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        if (accountStatus !== "" && accountType !== undefined) {
          const { accounts } = await fetchUserAccount({
            type: accountType,
            status: accountStatus,
            ...(search && { keyword: search }),
            size: TABLE_PAGE_SIZE,
          });
          setAccounts(accounts);
        }
      } catch (err: any) {
        handleFailure(err);
      }
    };

    void getAllUsers();
  }, [search, accountType, accountStatus]);

  useEffect(() => {
    if (account) {
      setIsFromSelected(true);
    }
  }, [account]);

  const onFormSubmit = async (values: FormValues) => {
    if (account?.account_id !== undefined) {
      try {
        if (fromAmount <= ((balance?.available_amount as unknown) as string)) {
          const res = await getAccountRate({
            buy_currency: values.currencyTo,
            sell_currency: values.currencyFrom,
            operation: "buy",
            amount: fromAmount,
            partyId: account.owner_id,
          });
          await createTemporaryRate(
            res.temporary_rate_id,
            account.owner_id as number
          );
          dispatch(
            promptsSlice.actions.openSnackbar({
              message: t("payment__success__message"),
              type: "success",
            })
          );
          history.push("/conversions");
        } else {
          dispatch(
            promptsSlice.actions.openSnackbar({
              message: "Insufficient balance",
              type: "warning",
            })
          );
        }
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    }
  };

  const exchangeFromTo = (value: string) => {
    setToAmount("-1");
    setFromAmount(value);
  };

  const exchangeToFrom = (value: string) => {
    setFromAmount("-1");
    setToAmount(value);
  };

  const getExchangeRate = async () => {
    if (currencyTo && currencyFrom && currencyTo !== currencyFrom) {
      try {
        const res = await getAccountRate({
          buy_currency: currencyTo,
          sell_currency: currencyFrom,
          operation: "buy",
          amount: 100,
          partyId: account?.owner_id || 0,
        });
        setExchangeRate(res.client_rate);
      } catch (err: any) {
        handleFailure(err);
      }
    }
  };

  useEffect(() => {
    if (currencyTo && currencyFrom) {
      getExchangeRate();
    }
  }, [currencyTo, currencyFrom]);

  useEffect(() => {
    const interval = setInterval(getExchangeRate, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [account, currencyTo, currencyFrom]);

  return (
    <MainTemplate>
      <QuidTitle>{t("internal__conversion__page__title")}</QuidTitle>
      <div className={classes.container}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <FormProvider {...methods}>
            <div className={classes.containerInner}>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__conversion__head__account__label")}
                </QuidTitle>
                {!isFromSelected === true && (
                  <div>
                    <QuidTextField
                      name="account_type"
                      textFieldProps={{
                        select: true,
                        fullWidth: true,
                      }}
                      rules={{
                        required: t("from__account__type__required") as string,
                      }}
                      defaultValues={defaultValues}
                      label={t("from__account__type")}
                    >
                      <MenuItem value="">{t("from__account__type")}</MenuItem>
                      {accountTypes?.map((option: AccountDataset, index) => (
                        <MenuItem
                          key={`${option}-${index}`}
                          value={option.code}
                        >
                          {option.text}
                        </MenuItem>
                      ))}
                    </QuidTextField>
                    {process.env.REACT_APP_KEYCLOAK_REALM === "toonie" &&
                      accountType && (
                        <QuidTextField
                          name="account_status"
                          textFieldProps={{
                            select: true,
                            fullWidth: true,
                          }}
                          rules={{
                            required: t(
                              "from__account__status__required"
                            ) as string,
                          }}
                          defaultValues={defaultValues}
                          label={t("from__account__status")}
                        >
                          {accountStatuses?.map(
                            (option: AccountDataset, index) => (
                              <MenuItem
                                key={`${option}-${index}`}
                                value={option.code}
                              >
                                {option.text}
                              </MenuItem>
                            )
                          )}
                        </QuidTextField>
                      )}
                    <TextField
                      label={t("filter__search")}
                      name="from_search"
                      variant="outlined"
                      fullWidth
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setSearch(e.target.value)
                      }
                      value={search}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <div className={classes.searchBox}>
                              <SearchIcon color="#fff" />
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {accounts?.map(
                      (account: AccountResponse, index: number) => (
                        <div
                          key={index}
                          onClick={() => {
                            setAccount(account);
                          }}
                          className={classes.accountDetailsWrapper}
                        >
                          <div>
                            <Typography variant="body1">
                              {account.account_name}
                              <br />
                              <small>{account.account_holder}</small>
                            </Typography>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                )}
                {account && <DetailsBlock label="" toPrint={accountDetails} />}
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__conversion__head__currencies__label")}
                </QuidTitle>
                {account && (
                  <div>
                    <QuidTextField
                      rules={{
                        required: t("currencyFrom__required") as string,
                      }}
                      name="currencyFrom"
                      defaultValues={defaultValues}
                      label={t("internal__conversion__currency_from")}
                      textFieldProps={{ select: true, fullWidth: true }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setCurrencyFrom(e.target.value);
                        getExchangeRate();
                      }}
                    >
                      {account?.balances?.map(
                        (balance: Balance, index: number) => {
                          return (
                            <MenuItem
                              key={`${balance.currency}-${index}`}
                              value={balance.currency}
                            >
                              {balance?.currency}
                            </MenuItem>
                          );
                        }
                      )}
                    </QuidTextField>
                    <QuidTextField
                      rules={{
                        required: t("currencyTo__required") as string,
                      }}
                      name="currencyTo"
                      defaultValues={defaultValues}
                      label={t("internal__conversion__currency_to")}
                      textFieldProps={{ select: true, fullWidth: true }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setCurrencyTo(e.target.value);
                        getExchangeRate();
                      }}
                    >
                      {account?.balances?.map(
                        (balance: Balance, index: number) => {
                          return (
                            <MenuItem
                              key={`${balance.currency}-${index}`}
                              value={balance.currency}
                            >
                              {balance?.currency}
                            </MenuItem>
                          );
                        }
                      )}
                    </QuidTextField>
                  </div>
                )}
              </div>
              <div className={classes.column4}>
                <QuidTitle fontSize={18} weight={500}>
                  {t("internal__conversion__head__details__label")}
                </QuidTitle>
                {currencyFrom &&
                  currencyTo &&
                  currencyFrom !== currencyTo &&
                  (exchageRate ? (
                    <>
                      <TextField
                        label={t("internal__conversion__amount_from")}
                        name="amount"
                        type="number"
                        fullWidth={true}
                        value={
                          fromAmount !== "-1"
                            ? fromAmount
                            : (
                                parseFloat(toAmount) * parseFloat(exchageRate)
                              ).toFixed(2)
                        }
                        onChange={(e) => {
                          exchangeFromTo(e?.target?.value);
                        }}
                        onBlur={(e) => {
                          const value = parseFloat(e?.target?.value).toFixed(2);
                          exchangeFromTo(value);
                        }}
                        variant="outlined"
                        margin="none"
                        className={classes.textField}
                      />
                      <TextField
                        label={t("internal__conversion__amount_to")}
                        type="number"
                        fullWidth={true}
                        value={
                          toAmount !== "-1"
                            ? toAmount
                            : (
                                parseFloat(fromAmount) / parseFloat(exchageRate)
                              ).toFixed(2)
                        }
                        onChange={(e) => {
                          exchangeToFrom(e?.target?.value);
                        }}
                        onBlur={(e) => {
                          const value = parseFloat(e?.target?.value).toFixed(2);
                          exchangeToFrom(value);
                        }}
                        variant="outlined"
                        margin="none"
                        className={classes.textField}
                      />
                      <TextField
                        label={t("internal__conversion__exchage_rate")}
                        name="exchageRate"
                        type="number"
                        fullWidth={true}
                        value={exchageRate}
                        variant="outlined"
                        margin="none"
                        className={classes.textField}
                        disabled={true}
                      />
                      <QuidTextField
                        textFieldProps={{ fullWidth: true }}
                        rules={{
                          required: t("reason__required") as string,
                        }}
                        name="reason"
                        label={t("internal__conversion__form__reason__label")}
                        defaultValues={defaultValues}
                      />
                    </>
                  ) : (
                    <Loader />
                  ))}
              </div>
              {balance && currencyFrom && (
                <div className={classes.amountdetails}>
                  <div className={classes.column4}>
                    <QuidTitle fontSize={18} weight={500}>
                      {t("internal__conversion__head__amounts__label")}
                    </QuidTitle>
                    <DetailsBlock label="" toPrint={transactionDetails} />
                  </div>
                </div>
              )}
            </div>
            <div className={classes.containerInnerButtons}>
              <Button variant="contained" color="primary" type="submit">
                {t("internal__conversion__create__button")}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={onResetForm}
              >
                {t("internal__conversion__reset__button")}
              </Button>
            </div>
          </FormProvider>
        </form>
      </div>
    </MainTemplate>
  );
};

export default PaymentsInternalConversion;
