import {
  makeStyles,
  TextField,
  InputAdornment,
  MenuItem,
  Button,
} from "@material-ui/core";
import { CellParams, ColDef, PageChangeParams } from "@material-ui/data-grid";
import clsx from "clsx";
import CsvIcon from "components/atoms/icons/CsvIcon";
import SearchIcon from "components/atoms/icons/SearchIcon";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { ServerFailure } from "features/core/Failure";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleFailure } from "resHandlers";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountsCurrencies,
  getAccountsTransactionStatus,
} from "store/reducers/app.reducer";
import { MoneyFormatter } from "shared/formatters/MoneyFormatter";
import { TABLE_PAGE_SIZE } from "shared/constants/index";
import PaymentDetailModal from "../payments/PaymentDetailModal";
import { NetworkFailure } from "../../../features/core/NetworkFailure";
import promptsSlice from "../../../store/reducers/prompts.reducer";
import {
  fetchAccountTransactionsByBalanceId,
  fetchTransactionDetailsByTransactionId,
} from "api/accounts";
import DetailIcon from "components/atoms/icons/DetailIcon";
import StatusBadge from "components/atoms/StatusBadge";
import {
  AccountDataset,
  AccountDatasetCurrency,
} from "entities/accounts/AccountDataset";
import {
  TransactionDetailResponse,
  Transaction,
  FetchTransactionDetailParams,
} from "entities/accounts/Transaction";
import { Pagination } from "entities/accounts/Pagination";
import { formatDate } from "utils";
import { getAccountsTransactionTypes } from "store/reducers/dataset.reducer";
export interface TransactionRevenueDetailProps {
  balanceId: number;
}

const useStyles = makeStyles((theme) => ({
  mt16: {
    marginTop: 16,
  },
  flexContainer: {
    display: "flex",
  },
  flexRow: {
    flexDirection: "row",
  },
  flexColumn: {
    flexDirection: "column",
  },
  flex1: {
    flex: 1,
  },
  flexSpace: {
    flex: 0.1,
  },
  flexCsv: {
    flex: 0.1,
  },
  csvBtn: {
    height: "100%",
    "&.MuiButton-root": {
      borderRadius: 4,
      width: "100%",
    },
  },
  searchBox: {
    width: "37px",
    height: "37px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "30px",
    backgroundColor: theme.palette.secondary.main,
  },
  status: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
}));

const TransactionRevenueDetail: React.FC<TransactionRevenueDetailProps> = ({
  balanceId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("account");
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    search: "",
    from: "",
    to: "",
    status: "",
    currency: "",
  });

  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [transactionDetail, setTransactionDetail] = useState<
    TransactionDetailResponse | undefined
  >();
  const [pagination, setPagination] = useState({} as Pagination);
  const [transactions, setTransactions] = useState([] as Transaction[]);

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const res = await fetchAccountTransactionsByBalanceId({
          balance_id: balanceId,
          page: pageNo,
          size: TABLE_PAGE_SIZE,
          ...(filter.from && { date_from: filter.from }),
          ...(filter.to && { date_to: filter.to }),
        });
        const { pagination, transaction_list: transactions } = res;
        setPagination(pagination);
        setTransactions(transactions);
      } catch (err: any) {
        handleFailure(err);
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, [filter, pageNo]);

  const onPageChange = (param: PageChangeParams): void => {
    setPageNo(param.page - 1);
  };

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: t("listTable__header_name__paymentId"),
      flex: 0.5,
    },
    {
      field: "status",
      headerName: t("transactionTable__header_name__status"),
      flex: 1,
      renderCell: (params) => (
        <div className={classes.status}>
          <StatusBadge status={params?.row?.status} />
        </div>
      ),
    },
    {
      field: "type",
      headerName: t("transactionTable__header_name__type"),
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: t("listTable__header_name__createdAt"),
      flex: 1,
      renderCell: (params) => (
        <div className={classes.dateType}>
          {formatDate(params.row?.created_at, "dd/MM/yyyy")}
          <small>{formatDate(params.row?.created_at, "HH:mm")}</small>
        </div>
      ),
    },
    {
      field: "completedAt",
      flex: 1,
      headerName: t("listTable__header_name__completedAt"),
      renderCell: (params) => (
        <div className={classes.dateType}>
          {formatDate(params.row?.created_at, "dd/MM/yyyy")}
          <small>{formatDate(params.row?.created_at, "HH:mm")}</small>
        </div>
      ),
    },
    {
      field: "amount",
      flex: 1,
      headerName: t("listTable__header_name__amount"),
      valueFormatter: (params: CellParams) =>
        MoneyFormatter(params?.row?.amount, params?.row?.currency),
    },
    {
      field: "reference",
      headerName: t("transactionTable__header_name__reference"),
      flex: 1,
    },
    {
      field: "receipt",
      flex: 1,
      headerName: t("transactionTable__header_name__receipt"),
      renderCell: (params) => {
        const id = params?.row?.id as number;
        if (!id) {
          return <DetailIcon />;
        }
        return (
          <Button
            onClick={() =>
              fetchTransactionDetail({
                balance_id: params?.row?.balance_id,
                transaction_id: params?.row?.id as number,
              })
            }
          >
            <DetailIcon />
          </Button>
        );
      },
    },
  ];

  const fetchTransactionDetail = async (
    params: FetchTransactionDetailParams
  ) => {
    setLoading(true);
    try {
      const res = await fetchTransactionDetailsByTransactionId(params);
      setTransactionDetail(res);
      setModalOpen(true);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const currencies = useSelector(getAccountsCurrencies);
  const transactionTypes = useSelector(getAccountsTransactionTypes);
  const transactionStatuses = useSelector(getAccountsTransactionStatus);
  const flexRow = clsx(classes.flexContainer, classes.flexRow);
  const flexRowMargin = clsx(flexRow, classes.mt16);
  return (
    <>
      <PaymentDetailModal
        open={modalOpen}
        setOpen={setModalOpen}
        data={transactionDetail}
      />
      <div className={flexRowMargin}>
        <div className={classes.flex1}>
          <TextField
            label={t("filter__search")}
            variant="outlined"
            fullWidth
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({ ...state, search: e.target.value }))
            }
            value={filter.search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className={classes.searchBox}>
                    <SearchIcon color="#ffffff" />
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classes.flexSpace}></div>
        <div className={classes.flex1}>
          <TextField
            label={t("filter__from")}
            variant="outlined"
            type="date"
            fullWidth
            value={filter.from}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({ ...state, to: e.target.value }))
            }
          />
        </div>
        <div className={classes.flexSpace}></div>
        <div className={classes.flex1}>
          <TextField
            label={t("filter__to")}
            variant="outlined"
            type="date"
            fullWidth
            value={filter.to}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({ ...state, to: e.target.value }))
            }
          />
        </div>
        <div className={classes.flexSpace}></div>
        <div className={classes.flex1}>
          <TextField
            label={t("filter__customer")}
            variant="outlined"
            select
            fullWidth
            value={filter.status}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({
                ...state,
                status: e.target.value,
              }))
            }
          >
            <MenuItem value="">{t("filter__customer")}</MenuItem>
            {transactionTypes?.map((option: AccountDataset, index: number) => (
              <MenuItem key={`${option}-${index}`} value={option.code}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className={classes.flexSpace}></div>
        <div className={classes.flex1}>
          <TextField
            label={t("filter__status")}
            variant="outlined"
            select
            fullWidth
            value={filter.status}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({
                ...state,
                status: e.target.value,
              }))
            }
          >
            <MenuItem value="">{t("filter__status")}</MenuItem>
            {transactionStatuses?.map(
              (option: AccountDataset, index: number) => (
                <MenuItem key={`${option}-${index}`} value={option.code}>
                  {option.text}
                </MenuItem>
              )
            )}
          </TextField>
        </div>
        <div className={classes.flexSpace}></div>
        <div className={classes.flex1}>
          <TextField
            label={t("filter__currency")}
            variant="outlined"
            select
            fullWidth
            value={filter.currency}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilter((state) => ({ ...state, currency: e.target.value }))
            }
          >
            <MenuItem value="">{t("filter__currency")}</MenuItem>
            {currencies?.map(
              (currency: AccountDatasetCurrency, index: number) => (
                <MenuItem
                  key={`${currency.code}-${index}`}
                  value={currency.code}
                >
                  {currency?.text}
                </MenuItem>
              )
            )}
          </TextField>
        </div>
        <div className={classes.flexSpace}></div>
        <div className={classes.flexCsv}>
          <Button variant="outlined" className={classes.csvBtn}>
            <CsvIcon />
          </Button>
        </div>
      </div>
      <QuidDataGrid
        onPageChange={onPageChange}
        loading={loading}
        columns={columns}
        rows={transactions}
        rowCount={pagination?.total_entries}
      />
    </>
  );
};

export default TransactionRevenueDetail;
