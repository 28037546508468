import { ChangeEvent, useEffect, useState } from "react";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import {
  makeStyles,
  createStyles,
  Button,
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { createOpportunity, fetchOpportunities } from "api/opportunities";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { Pagination } from "entities/accounts/Pagination";
import { TABLE_PAGE_SIZE } from "shared/constants";
import { Opportunity } from "entities/opportunities/opportunityEntity";
import { useTranslation } from "react-i18next";
import DetailButton from "components/atoms/DetailButton";
import { useHistory } from "react-router";
import { getInvestmentsOpportunitiesStatus } from "store/reducers/app.reducer";
import { useSelector } from "react-redux";
import { InvestmentsDataset } from "entities/investments/InvestmentsDatasets";
import SearchIcon from "components/atoms/icons/SearchIcon";
import ConfirmationModal from "components/atoms/ConfirmationModal";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

const useStyles = makeStyles((theme) =>
  createStyles({
    addressSpacing: {
      marginTop: 30,
      marginBottom: 30,
    },
    accountContainer: {
      width: "100%",
      marginTop: 30,
      minHeight: 500,
    },
    container: {
      paddingBottom: "60px",
    },
    btnWrapper: {
      display: "flex",
      justifyContent: "end",
    },
    searchBox: {
      width: "37px",
      height: "37px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "30px",
      backgroundColor: theme.palette.secondary.main,
    },
  })
);

export interface Filters {
  status: string;
}

const OpportunitiesList = () => {
  const { t } = useTranslation("opportunities");
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [opportunityToClone, setOpportunityToClone] = useState<Opportunity>();
  const [opportunities, setOpportunities] = useState([] as Array<Opportunity>);
  const [pagination, setPagination] = useState({} as Pagination);
  const [filters, setFilters] = useState({} as Filters);
  const [search, setSearch] = useState("");

  const fetchOpportunitiesList = async () => {
    setLoading(true);
    try {
      const res = await fetchOpportunities({
        page,
        size: TABLE_PAGE_SIZE,
        ...(search && { name: search }),
        ...(filters.status && { status: filters.status }),
      });
      setPagination(res.pagination);
      setOpportunities(res.opportunityList);
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;

      promptsSlice.actions.openSnackbar({
        message,
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchOpportunitiesList();
  }, [page, filters, search]);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  const opportunitiesStatus = useSelector(getInvestmentsOpportunitiesStatus);

  const handleCloneClick = (opportunityToClone: any) => {
    setIsModalOpen(true);
    setOpportunityToClone(opportunityToClone);
  };

  const onSubmitClone = async () => {
    if (opportunityToClone) {
      try {
        await createOpportunity({
          name: opportunityToClone?.name,
          currency: opportunityToClone?.currency,
          assetClass: opportunityToClone?.assetClass,
          minRequested: opportunityToClone?.minRequested,
          maxRequested: opportunityToClone?.maxRequested,
          referralPercentage: opportunityToClone?.referralPercentage,
          network: opportunityToClone?.smartContract?.network
            ? opportunityToClone?.smartContract?.network
            : "",
          horizon: opportunityToClone?.horizon,
          stage: opportunityToClone?.stage,
          sector: opportunityToClone?.sector,
          tokenPrice: opportunityToClone?.tokenPrice
            ? opportunityToClone?.tokenPrice
            : "",
          valuation: opportunityToClone?.valuation
            ? opportunityToClone?.valuation
            : "",
          shortDescription: opportunityToClone?.shortDescription,
          longDescription: opportunityToClone?.longDescription,
          noToken: opportunityToClone?.noToken
            ? parseInt(opportunityToClone?.noToken?.toString(), 10)
            : 0,
          mediumImageId: opportunityToClone?.mediumImageId,
          bigImageId: opportunityToClone?.bigImageId,
          logoImageId: opportunityToClone?.logoImageId,
          details: opportunityToClone?.details,
          keyFacts: opportunityToClone?.keyFacts,
          step: opportunityToClone?.step,
          smartContract: opportunityToClone?.smartContract?.name
            ? {
                name: opportunityToClone?.smartContract.name,
                template: opportunityToClone?.smartContract.template,
                amount: opportunityToClone?.smartContract.amount,
                description: opportunityToClone?.smartContract.description,
                precision:
                  opportunityToClone?.smartContract.name &&
                  opportunityToClone?.smartContract?.precision
                    ? parseInt(
                        opportunityToClone?.smartContract.precision?.toString(),
                        10
                      )
                    : undefined,
                uintAmount: opportunityToClone?.smartContract.name
                  ? 1
                  : undefined,
              }
            : undefined,
          beneficiaryWallets: {
            wallets: [
              {
                currency: opportunityToClone?.beneficiaryWallets?.wallets?.[0]
                  ?.currency
                  ? opportunityToClone?.beneficiaryWallets?.wallets?.[0]
                      ?.currency
                  : "",
                walletId: opportunityToClone?.beneficiaryWallets?.wallets?.[0]
                  ?.walletId
                  ? opportunityToClone?.beneficiaryWallets?.wallets?.[0]
                      ?.walletId
                  : "",
              },
            ],
          },
          startDate: opportunityToClone?.startDate
            ? `${format(
                new Date(opportunityToClone?.startDate),
                "yyyy-MM-dd"
              )}T00:00:00.000Z`
            : "",
          endDate: opportunityToClone?.endDate
            ? `${format(
                new Date(opportunityToClone?.endDate),
                "yyyy-MM-dd"
              )}T00:00:00.000Z`
            : "",
          supportsPartial: false,
          opportunityInvestmentSetupRequest: {
            type: opportunityToClone?.opportunitySetup.type,
            apy: opportunityToClone?.opportunitySetup.apy.toString(),
            investment: opportunityToClone?.opportunitySetup.investment.toString(),
            duration: opportunityToClone?.opportunitySetup.duration.toString(),
          },
          streamableWalletAddress: opportunityToClone?.streamableWalletAddress,
          streamableCurrency: opportunityToClone?.streamableCurrency,
        });
        dispatch(
          promptsSlice.actions.openSnackbar({
            message: t("opportunity__clone__success__message"),
            type: "success",
          })
        );
        void fetchOpportunitiesList();
        setIsModalOpen(false);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    }
  };

  const columns: ColDef[] = [
    {
      flex: 1,
      field: "id",
      headerName: "ID",
    },
    {
      flex: 1,
      field: "name",
      headerName: "name",
    },
    {
      flex: 1,
      field: "status",
      headerName: "status",
    },
    {
      field: "clone",
      headerName: t("details__label__clone"),
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCloneClick(params?.row)}
        >
          {t("details__label__clone")}
        </Button>
      ),
    },
    {
      field: "",
      headerName: t("details__label__details"),
      renderCell: (params) => (
        <DetailButton to={`/opportunities/${params?.row?.id}`} />
      ),
    },
  ];

  return (
    <>
      <ConfirmationModal
        isOpen={isModalOpen}
        title={t("opportunity__clone_title")}
        description={t("opportunity__clone_description")}
        onCloseModal={() => setIsModalOpen(false)}
        labelButtonClose="No"
        labelButtonSubmit="Yes"
        onSubmit={onSubmitClone}
      />
      <div className={classes.container}>
        <div className={classes.btnWrapper}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/opportunities/create")}
          >
            {t("opportunity__create_button")}
          </Button>
        </div>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={4}>
            <TextField
              label={t("filter__search")}
              variant="outlined"
              fullWidth
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
              value={search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <div className={classes.searchBox}>
                      <SearchIcon color="#fff" />
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label={t("filter__status")}
              variant="outlined"
              type="string"
              fullWidth
              select
              value={filters.status}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setFilters((state) => ({
                  ...state,
                  status: e.target.value,
                }))
              }
            >
              <MenuItem value="">{t("all_opportunities")}</MenuItem>
              {opportunitiesStatus?.map(
                (option: InvestmentsDataset, index: number) => (
                  <MenuItem key={`${option}-${index}`} value={option.code}>
                    {option.text}
                  </MenuItem>
                )
              )}
            </TextField>
          </Grid>
        </Grid>
        <div className={classes.accountContainer}>
          <QuidDataGrid
            disableSelectionOnClick
            onPageChange={onPageChange}
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={opportunities}
            rowCount={pagination?.total_entries}
          />
        </div>
      </div>
    </>
  );
};

export default OpportunitiesList;
