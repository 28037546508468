import { ChangeEvent, useEffect, useState } from "react";
import { ColDef, PageChangeParams } from "@material-ui/data-grid";
import {
  makeStyles,
  createStyles,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
import QuidDataGrid from "components/atoms/QuidDataGrid";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { ONE_HOUR, TABLE_PAGE_SIZE } from "shared/constants";
import { useTranslation } from "react-i18next";
import DetailIcon from "components/atoms/icons/DetailIcon";
import { useDispatch, useSelector } from "react-redux";
import { getInvestmentsBo } from "@portit/core/api/Investments";
import {
  InvestmentDetailResponse,
  InvestmentsParams,
  Pagination,
} from "@portit/core/entities/Investments";
import InvestmentDetailModal from "./InvestmentDetailModal";
import { InvestmentsDataset } from "entities/investments/InvestmentsDatasets";
import { getInvestmentsInvestmentsStatus } from "store/reducers/app.reducer";
import { getCustomerDetailsByCustomerId } from "api/customers";
import { postCryptoPaymentsManagementTriggerConfirmation } from "@portit/core/api/Crypto";

const useStyles = makeStyles((theme) =>
  createStyles({
    detailIconContainer: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    searchBox: {
      width: "37px",
      height: "37px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "30px",
      backgroundColor: theme.palette.secondary.main,
    },
    investmentsContainer: {
      width: "100%",
      marginTop: 30,
      minHeight: 500,
    },
    redStatus: {
      color: "#ff0000",
    },
  })
);

const InvestmentsList = () => {
  const { t } = useTranslation("investments");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [
    investmentDetails,
    setInvestmentDetails,
  ] = useState<InvestmentDetailResponse>({} as InvestmentDetailResponse);

  const [investments, setInvestments] = useState(
    [] as InvestmentDetailResponse[]
  );
  const [pagination, setPagination] = useState({} as Pagination);
  const [filters, setFilters] = useState({} as InvestmentsParams);

  useEffect(() => {
    const investmentsList = async () => {
      setLoading(true);
      try {
        const res = await getInvestmentsBo({
          page,
          size: TABLE_PAGE_SIZE,
          ...(filters.max && { max: filters.max }),
          ...(filters.fromDate && {
            fromDate: `${filters.fromDate}T00:00:00.000Z`,
          }),
          ...(filters.toDate && { toDate: `${filters.toDate}T00:00:00.000Z` }),
          ...(filters.min && { min: filters.min }),
          ...(filters.status && { status: filters.status }),
          ...(filters.partyId && { partyId: filters.partyId }),
        });
        res.investmentsList.map((investement) => {
          const t = getUsernameByPartyId(investement.partyId);
          return { ...investement, CustomerName: t };
        });
        setPagination(res.pagination);
        setInvestments(res.investmentsList);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;

        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        });
      } finally {
        setLoading(false);
      }
    };

    void investmentsList();
  }, [dispatch, page, filters]);

  const onPageChange = (param: PageChangeParams): void => {
    setPage(param.page - 1);
  };

  const doActionModal = (isOpen: boolean, value: InvestmentDetailResponse) => {
    if (isOpen) {
      setInvestmentDetails(value);
      setModalOpen(isOpen);
    }
  };

  const getUsernameByPartyId = async (id: number | string) => {
    try {
      const res = await getCustomerDetailsByCustomerId(id);
      if (res?.customerList?.type === "INDIVIDUAL") {
        return `${res.customerList.name} ${res.customerList.surname}`;
      } else {
        return `${res?.customerList?.company?.companyName}`;
      }
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  const checkIfInvestmentIsRed = (params: any) => {
    if (params?.status && params?.date) {
      const isPendingBlockchain =
        params?.status?.toLowerCase() === "pending_blockchain";

      const currentDate = new Date().toISOString().slice(0, 10);
      const investmentDate = new Date(params?.date).toISOString().slice(0, 10);

      const currentTime = new Date().getTime();
      const investmentTime = new Date(params?.date).getTime();

      return (
        isPendingBlockchain &&
        (investmentDate < currentDate ||
          (investmentDate === currentDate &&
            investmentTime - currentTime >= ONE_HOUR))
      );
    }
  };

  const isConfirmButtonVisible = (params: any) => {
    if (params?.status && params?.date) {
      const isPendingBlockchain =
        params?.status?.toLowerCase() === "pending_blockchain";

      const currentDate = new Date().toISOString().slice(0, 10);
      const investmentDate = new Date(params?.date).toISOString().slice(0, 10);

      const currentTime = new Date().getTime();
      const investmentTime = new Date(params?.date).getTime();

      return (
        (isPendingBlockchain &&
          investmentDate === currentDate &&
          investmentTime - currentTime < ONE_HOUR) ||
        (isPendingBlockchain && investmentDate !== currentDate)
      );
    }
  };

  const columns: ColDef[] = [
    {
      flex: 1,
      field: "id",
      headerName: t("listTable__header_name__id"),
    },
    {
      flex: 1,
      field: "name",
      headerName: t("listTable__header_name__name"),
      renderCell: (params) => {
        return <>{params?.row?.opportunity?.name}</>;
      },
    },
    {
      flex: 1,
      field: "customerName",
      headerName: t("listTable__header_name__customer_name"),
      renderCell: (params) => {
        if (params?.row?.customer?.type === "INDIVIDUAL") {
          return (
            <>
              {params?.row?.customer?.surname} {params?.row?.customer?.name}
            </>
          );
        } else {
          return <>{params?.row?.customer?.company?.name}</>;
        }
      },
    },
    {
      flex: 1,
      field: "investmentType",
      headerName: t("listTable__header_name__investment_type"),
    },
    {
      flex: 1,
      field: "status",
      headerName: t("listTable__header_name__status"),
      renderCell: (params) => {
        if (checkIfInvestmentIsRed(params?.row)) {
          return (
            <span className={classes.redStatus}>{params?.row?.status}</span>
          );
        } else {
          return <span>{params?.row?.status}</span>;
        }
      },
    },
    {
      field: "",
      headerName: t("listTable__header_name__details"),
      renderCell: (params) => {
        return (
          <>
            <div
              className={classes.detailIconContainer}
              onClick={() =>
                doActionModal(
                  true,
                  (params?.row as unknown) as InvestmentDetailResponse
                )
              }
            >
              <DetailIcon />
            </div>
          </>
        );
      },
    },
  ];

  const investementStatus = useSelector(getInvestmentsInvestmentsStatus);

  const confirmOpportunity = async () => {
    try {
      if (investmentDetails?.transactionHash && investmentDetails?.currency) {
        await postCryptoPaymentsManagementTriggerConfirmation(
          investmentDetails?.transactionHash,
          investmentDetails.currency
        );

        dispatch(
          promptsSlice.actions.openSnackbar({
            message: t("snackbar__message__entity_confirmed"),
            type: "success",
          })
        );
      }
    } catch (err: any) {
      const message =
        err instanceof ServerFailure
          ? (err as ServerFailure)?.error?.message
          : (err as NetworkFailure)?.message;
      dispatch(
        promptsSlice.actions.openSnackbar({
          message,
          type: "error",
        })
      );
    }
  };

  return (
    <>
      <InvestmentDetailModal
        open={modalOpen}
        setOpen={setModalOpen}
        data={investmentDetails}
        confirmOpportunity={confirmOpportunity}
        isVisibleConfirmButton={isConfirmButtonVisible(investmentDetails)}
      />
      <Grid container direction="row" spacing={2}>
        <Grid item xs={2}>
          <TextField
            label={t("filter__party_id")}
            variant="outlined"
            type="string"
            fullWidth
            value={filters.partyId}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilters((state) => ({
                ...state,
                partyId: (e.target.value as unknown) as number,
              }))
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label={t("filter__fromDate")}
            variant="outlined"
            type="date"
            fullWidth
            value={filters.fromDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilters((state) => ({ ...state, fromDate: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label={t("filter__toDate")}
            variant="outlined"
            type="date"
            fullWidth
            value={filters.toDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilters((state) => ({ ...state, toDate: e.target.value }))
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label={t("filter__min")}
            variant="outlined"
            type="string"
            fullWidth
            value={filters.min}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilters((state) => ({
                ...state,
                min: (e.target.value as unknown) as number,
              }))
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label={t("filter__max")}
            variant="outlined"
            type="string"
            fullWidth
            value={filters.max}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilters((state) => ({
                ...state,
                max: (e.target.value as unknown) as number,
              }))
            }
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            label={t("filter__status")}
            variant="outlined"
            type="string"
            fullWidth
            select
            value={filters.status}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFilters((state) => ({
                ...state,
                status: e.target.value,
              }))
            }
          >
            <MenuItem value=""></MenuItem>
            {investementStatus?.map(
              (option: InvestmentsDataset, index: number) => (
                <MenuItem key={`${option}-${index}`} value={option.code}>
                  {option.text}
                </MenuItem>
              )
            )}
          </TextField>
        </Grid>
      </Grid>
      <div className={classes.investmentsContainer}>
        {investments && (
          <QuidDataGrid
            disableSelectionOnClick
            onPageChange={onPageChange}
            sortModel={[{ field: "id", sort: "desc" }]}
            loading={loading}
            columns={columns}
            rows={investments ?? []}
            rowCount={pagination?.total_entries}
          />
        )}
      </div>
    </>
  );
};

export default InvestmentsList;
