import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router";
import ApproveRejectButtons from "./ApproveRejectButtons";
import { FormProvider, useForm } from "react-hook-form";
import { handleFailure, handleSuccessfulMessage } from "resHandlers";
import { VerificationRequest } from "entities/tasks/Common";

const useStyles = makeStyles(() => ({
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  usefulLinks: {
    justifyContent: "center",
  },
  textarea: {
    marginTop: 18,
    marginBottom: 22,
  },
  container: {
    display: "flex",
    justifyContent: "center",
    padding: 50,
    backgroundColor: "#FAFAFA",
    paddingTop: 100,

    "& .box": {
      display: "flex",
      flexWrap: "wrap",
      padding: "50px 100px",
      backgroundColor: "#FFF",
      borderRadius: 60,

      "& .MuiFormControlLabel-root, & .MuiTextField-root, & .MuiNumberField-root": {
        width: "100%",
      },

      "& .reject": {
        textAlign: "center",
        marginBottom: 20,
        color: "#FF6F0F",
      },

      "& .actions": {
        minHeight: 50,
        margin: "0 10px",

        "&-reject": {
          backgroundColor: "#FF6F0F",
        },
      },
    },
  },
}));

interface ApproveRejectBoxSimpleProps {
  accept: (values: VerificationRequest) => Promise<unknown>;
  reject: (values: VerificationRequest) => Promise<unknown>;
}

const ApproveRejectBoxSimple = ({
  accept,
  reject,
}: ApproveRejectBoxSimpleProps) => {
  const classes = useStyles();
  const history = useHistory();

  const methods = useForm({
    mode: "onBlur",
  });

  const onReject = async (values: VerificationRequest) => {
    try {
      await reject(values);
      handleSuccessfulMessage("Action received");
      history.replace("/");
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  const onAccept = async (values: VerificationRequest) => {
    try {
      await accept(values);
      handleSuccessfulMessage("Action received");
      history.replace("/");
    } catch (err: any) {
      return handleFailure(err);
    }
  };

  return (
    <FormProvider {...methods}>
      <form>
        <Grid item xs={12} className={classes.container}>
          <Grid item xs={10} className="box">
            <Grid item xs={12}>
              <ApproveRejectButtons
                disabled={false}
                disableReject={false}
                disableAccept={false}
                onAccept={methods.handleSubmit(onAccept)}
                onReject={methods.handleSubmit(onReject)}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default ApproveRejectBoxSimple;
