import {
  fetchBankAccountTypes,
  fetchIdentificationTypes,
  fetchPaymentStatus,
  fetchTransactionStatus,
  fetchAccountTypes,
  fetchTransactionTypes,
  fetchTransactionInboundTypes,
  fetchTransactionOutboundTypes,
  fetchTransactionDirection,
  fetchPaymentTypes,
  fetchCurrencies,
  fetchCountry,
  fetchLegalEntities,
  fetchAccountStatus,
  fetchRoutingCodeTypes,
} from "api/accountDatasets";
import { AccountDatasets } from "entities/accounts/AccountDataset";

export async function fetchAllAccountDatasets(): Promise<AccountDatasets> {
  try {
    const res = await Promise.all<any>([
      fetchBankAccountTypes(),
      fetchIdentificationTypes(),
      fetchPaymentStatus(),
      fetchTransactionStatus(),
      fetchAccountTypes(),
      fetchTransactionTypes(),
      fetchTransactionInboundTypes(),
      fetchTransactionOutboundTypes(),
      fetchTransactionDirection(),
      fetchPaymentTypes(),
      fetchCurrencies(),
      fetchCountry(),
      fetchLegalEntities(),
      fetchAccountStatus(),
      fetchRoutingCodeTypes(),
    ]);

    const mapRes = res.map(({ values }) => values);

    const [
      bankAccountTypes,
      identificationTypes,
      paymentStatus,
      transactionStatus,
      accountTypes,
      transactionTypes,
      transactionInboundTypes,
      transactionOutboundTypes,
      transactionDirection,
      paymentTypes,
      currencies,
      countries,
      legalEntities,
      accountStatus,
      routingCodeTypes,
    ] = mapRes;

    return {
      bankAccountTypes,
      identificationTypes,
      paymentStatus,
      transactionStatus,
      accountTypes,
      transactionTypes,
      transactionInboundTypes,
      transactionOutboundTypes,
      transactionDirection,
      paymentTypes,
      currencies,
      countries,
      legalEntities,
      accountStatus,
      routingCodeTypes,
    };
  } catch (e) {
    throw e;
  }
}
