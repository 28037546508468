import { DialogContent, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import DetailsBlock from "components/molecules/verifications/DetailsBlock";
import FullScreenModal from "components/atoms/FullScreenModal";
import { EnhancedAddress } from "entities/clients/CustomerBackofficeEntity";

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    "& .MuiPaper-root": {
      maxWidth: 1000,
      width: "100%",
      padding: "0 50px",
      "& .MuiDialogTitle-root .MuiTypography-root": {
        margin: "25px 0",
        fontSize: 28,
        color: theme.palette.secondary.main,
        textAlign: "center",
      },
    },
  },
  dialogContent: {
    width: "500px",
    padding: 50,
  },
  modalBody: {
    display: "flex",
    fontFamily: "Comfortaa,sans-serif",
    flexDirection: "column",
    width: "100%",
    marginTop: 32,
    alignItems: "center",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
  },
  form: {
    maxWidth: "100%",
  },
  flex1: {
    flex: 1,
  },
  mt56: {
    marginTop: 56,
  },
  mt16: {
    marginTop: 16,
  },
  listTitle: {
    fontSize: 16,
    fontWeight: 400,
    color: "#929292",
  },
  listValue: {
    fontSize: 16,
    color: "#37383C",
    fontWeight: 400,
  },
  sectionTitle: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    marginBottom: "5px",
  },
  detailTitle: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    fontWeight: 400,
  },
  title: {
    fontSize: "28px",
    fontWeight: 700,
    color: theme.palette.secondary.main,
    marginTop: 16,
    marginBottom: 32,
  },
  dateType: {
    display: "flex",
    flexDirection: "column",
    lineHeight: "20px",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: 56,
  },
  "& .actions": {
    minHeight: 50,
    margin: "0 10px",

    "&-reject": {
      backgroundColor: "#FF6F0F",
    },
  },
}));

export interface CurrentAddress {
  id?: number;
  partyId: string | number;
  type: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postalCode: string;
  country: string;
  longitude?: number;
  latitude?: number;
  use: string;
}

interface AddressDetailModalProps {
  addresses: EnhancedAddress[];
  addressId?: number;
  open: boolean;
  onCloseModal: () => void;
}

const AddressDetailModal: React.FC<AddressDetailModalProps> = ({
  onCloseModal,
  addressId,
  open,
  addresses,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("customer");

  if (!addressId) {
    return null;
  }

  const currentAddr = addresses.find((a) => a.id === addressId);

  const currentAddrToPrint: CurrentAddress = {
    id: currentAddr?.id,
    partyId: currentAddr?.partyId || "n/a",
    type: currentAddr?.type || "n/a",
    addressLine1: currentAddr?.addressLine1 || "n/a",
    addressLine2: currentAddr?.addressLine2 || "n/a",
    city: currentAddr?.city || "n/a",
    postalCode: currentAddr?.postalCode || "n/a",
    country: currentAddr?.country || "n/a",
    latitude: currentAddr?.latitude || 0,
    longitude: currentAddr?.longitude || 0,
    use: currentAddr?.use || "n/a",
  };

  if (!currentAddr) {
    return null;
  }

  return (
    <FullScreenModal open={open} onClose={() => onCloseModal()}>
      <div className={classes.modalBody}>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt56}>
            <Typography variant="h1" className={classes.title}>
              {t("address__detail__title")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction={"column"} alignItems={"center"}>
          <Grid item xs={12} className={classes.mt16}>
            <DialogContent className={classes.dialogContent}>
              <DetailsBlock label="" toPrint={currentAddrToPrint} />
            </DialogContent>
          </Grid>
        </Grid>
      </div>
    </FullScreenModal>
  );
};

export default AddressDetailModal;
