import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import MainTemplate from "templates/MainTemplate";
import { Grid, makeStyles } from "@material-ui/core";
import QuidTitle from "components/atoms/QuidTitle";
import { RouteComponentProps } from "react-router";
import InfoBox from "components/molecules/InfoBox";
import { WalletDetailResponse } from "@portit/core/entities/Crypto";
import CardDetails from "components/molecules/CardDetails";
import { fetchCryptoWalletManagementByPartyId } from "@portit/core/api/Crypto";
import { ServerFailure } from "features/core/Failure";
import { NetworkFailure } from "features/core/NetworkFailure";
import promptsSlice from "store/reducers/prompts.reducer";
import { getCustomerByCustomerId } from "api/customers";
import { CustomerResponse } from "entities/clients/CustomerBackofficeEntity";
import { getCompanyByCompanyId } from "api/companies";
import { Company } from "entities/clients/CompanyEntity";

const useStyles = makeStyles(() => ({
  container: {
    minHeight: "100px",
  },
  verticalAlign: {
    alignItems: "center",
  },
}));

const CryptoWalletPage: React.FC<
  RouteComponentProps<{ partyId: string; id: string }>
> = ({ match }) => {
  const { t } = useTranslation("crypto");
  const dispatch = useDispatch();
  const partyId = parseInt(match.params.partyId, 10);
  const id = parseInt(match.params.id, 10);
  const [details, setDetails] = useState<WalletDetailResponse>();
  const [customer, setCustomer] = useState<CustomerResponse>();
  const [company, setCompany] = useState<Company>();
  const classes = useStyles();

  useEffect(() => {
    const getWallet = async (): Promise<void> => {
      try {
        const res = await fetchCryptoWalletManagementByPartyId(partyId);
        setDetails(res);
      } catch (err: any) {
        const message =
          err instanceof ServerFailure
            ? (err as ServerFailure)?.error?.message
            : (err as NetworkFailure)?.message;
        dispatch(
          promptsSlice.actions.openSnackbar({
            message,
            type: "error",
          })
        );
      }
    };

    void getWallet();
  }, [dispatch]);

  useEffect(() => {
    const getCustomer = async (): Promise<void> => {
      if (details?.partyId) {
        try {
          const res = await getCustomerByCustomerId(details.partyId);
          setCustomer(res);
        } catch (err: any) {
          const res = await getCompanyByCompanyId(details.partyId);
          setCompany(res);
        }
      }
    };

    void getCustomer();
  }, [details]);

  let detailsData = [
    {
      title: t("wallet_details__party_id"),
      value: details?.partyId || "n/a",
    },
  ];

  if (customer) {
    detailsData = [
      ...detailsData,
      {
        title: t("wallet_details__customer_firstname"),
        value: customer.customer.name || "n/a",
      },
      {
        title: t("wallet_details__customer_surname"),
        value: customer.customer.surname || "n/a",
      },
    ];
  }
  if (company) {
    detailsData = [
      ...detailsData,
      {
        title: t("wallet_details__company_name"),
        value: company.companyName || "n/a",
      },
      {
        title: t("wallet_details__company_number"),
        value: company.companyNumber || "n/a",
      },
      {
        title: "",
        value: "",
      },
      {
        title: t("wallet_details__customer_id"),
        value: company.customer?.id || "n/a",
      },
      {
        title: t("wallet_details__customer_firstname"),
        value: company.customer?.name || "n/a",
      },
      {
        title: t("wallet_details__customer_surname"),
        value: company?.customer?.surname || "n/a",
      },
    ];
  }

  detailsData = [
    ...detailsData,
    {
      title: "",
      value: "",
    },
    {
      title: t("wallet_details__wallet_address"),
      value: details?.walletAddress || "n/a",
    },
  ];

  const cardLabelsCryptoColumn = [
    {
      label: t("user_details_card_crypto_tansactions"),
      path: `/crypto/${partyId}/wallets/${id}/transactions`,
    },
    {
      label: t("user_details_card_crypto_balances"),
      path: `/crypto/${partyId}/wallets/${id}/balances`,
    },
    {
      label: t("user_details_card_crypto_beneficiaries"),
      path: `/crypto/${partyId}/wallets/${id}/beneficiaries`,
    },
  ];

  return (
    <MainTemplate>
      <Grid container direction={"column"} alignItems={"center"}>
        <Grid item xs={12}>
          <QuidTitle>{t("cryptowallet__page__title")}</QuidTitle>
        </Grid>
      </Grid>
      <Grid container className={(classes.container, classes.verticalAlign)}>
        <Grid item xs={8}>
          <InfoBox
            title={t("cryptowallet__details_info__title")}
            items={detailsData}
          />
        </Grid>
        <Grid item xs={4}>
          <CardDetails
            isColumn={true}
            items={cardLabelsCryptoColumn}
            from={"wallet"}
          ></CardDetails>
        </Grid>
      </Grid>
    </MainTemplate>
  );
};

export default CryptoWalletPage;
